import { IconAttention } from '@loadsmart/icons'
import { Select as MirandaSelect } from '@loadsmart/loadsmart-ui'
import type { SelectOptionProps } from '@loadsmart/loadsmart-ui'
import {
  Drawer,
  Layout,
  Field,
  TextField,
  Text,
  ToggleGroup,
} from '@loadsmart/miranda-react'
import { useFormik, FormikProvider } from 'formik'
import { get, isEmpty } from 'lodash'
import { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'

import { FormField } from 'components/FormField'
import { ExpandableTextarea, Select } from 'components/Formik'
import { REQUIRED_FIELD_MESSAGE } from 'constants/errors'
import { useFacilityDetails } from 'hooks/useQuery'
import { createFacility, updateFacility } from 'services/shipments'
import analytics, { AnalyticsEvent } from 'utils/analytics'
import { setMomentTime } from 'utils/dateUtils'
import { noop } from 'utils/noop'
import queryClient from 'utils/queryClient'

import facilityFormAdapter from './adapters/facility'
import FacilityAddressField from './FacilityAddressField'
import { defaultInitialValues, daysOptions } from './form'
import { validationSchema } from './schema'
import { ScheduleWrapper, TimeInput } from './styles'
import type { FacilityDrawerProps, FieldSelect, OperatingDays } from './types'
import {
  countries,
  createFacilityAddress,
  formatActionLabel,
  getDrawerTitle,
  getSelectedCountry,
  getSelectedState,
  getStates,
} from './utils'
import WarehouseSection from './WarehouseSection'

const CountryOption = ({ value }: SelectOptionProps) => {
  const flag = value === 'US' ? '🇺🇸' : '🇨🇦'
  return (
    <MirandaSelect.Option value={value}>
      <Layout.Group gap="spacing-2" align="center">
        <Text color="color-text-tertiary" variant="body-sm">
          {flag} {value}
        </Text>
      </Layout.Group>
    </MirandaSelect.Option>
  )
}

export const FacilityDrawer = ({
  isOpen,
  handleCloseDrawer,
  callback,
  facilityUUID = '',
  initialValues,
  formikProps = {},
  onClosed,
}: FacilityDrawerProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false)

  const {
    data: facilityData,
    isLoading: isFacilityDataLoading,
    isError: isFacilityDataError,
  } = useFacilityDetails(facilityUUID, {
    enabled: isOpen && !!facilityUUID,
  })

  const { warehouse_uuid } = facilityData || {}

  if (isFacilityDataError) {
    toast.error('Could not load Facility details')
  }

  const title = getDrawerTitle(facilityUUID)

  const getFacilityInitialValues = () => {
    if (!isFacilityDataLoading && !isFacilityDataError && facilityData) {
      return facilityFormAdapter(facilityData)
    }
    if (!facilityUUID && !isEmpty(initialValues)) {
      return {
        ...initialValues,
        facility_address: createFacilityAddress(initialValues.facility_address),
      }
    }
    return {
      country: 'US',
    }
  }

  const formControl = useFormik({
    initialValues: {
      ...defaultInitialValues,
      ...getFacilityInitialValues(),
    },
    validationSchema,
    onSubmit: noop,
    enableReinitialize: true,
    ...formikProps,
  })

  const {
    values,
    touched,
    errors,
    handleChange,
    setFieldValue,
    setFieldTouched,
    resetForm,
    validateForm,
  } = formControl

  const hasError = Object.keys(errors).length > 0
  const hasTouched = Object.keys(touched).length > 0
  const canSubmit = !hasError && hasTouched && !isSubmitting

  const buildOperatingDays = () => {
    const days: OperatingDays = {
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
    }

    if (values.operating_days) {
      values.operating_days.forEach((item) => {
        days[item] = true
      })
    }

    return days
  }

  const { mutate: mutateNewFacility } = useMutation({
    mutationFn: createFacility,
    onError(error: any) {
      const alias = error?.response?.data?.data?.alias
      const zipcodeError = error?.response?.data?.data?.non_field_errors?.[0]

      if (alias) {
        toast.error(`The facility already exists: ${alias}`)
      } else if (zipcodeError) {
        toast.error(zipcodeError)
      } else {
        toast.error('Unable to create the facility')
      }

      setIsSubmitting(false)
    },
  })

  const customMutateNewFacility = (facilityValues: any) => {
    mutateNewFacility(facilityValues, {
      onSuccess: (data) => {
        const facilityDetails = {
          ...data,
          _type: 'generic',
        }

        callback(facilityDetails)
        queryClient.refetchQueries({ queryKey: ['retrieveShipperFacilities'] })

        setIsSubmitting(false)
        resetForm()
        handleCloseDrawer()

        analytics.track(AnalyticsEvent.FacilityManagementNewFacility)
        toast.info('Facility saved.')
      },
    })
  }

  const { mutate: mutateUpdateFacility } = useMutation({
    mutationFn: updateFacility,
    onError(error: any) {
      const alias = error?.response?.data?.data?.alias

      if (alias) {
        toast.error(`The facility already exists: ${alias}`)
      } else {
        toast.error('Unable to update the facility')
      }

      setIsSubmitting(false)
    },
  })

  const customMutateUpdateFacility = (facilityValues: any) => {
    mutateUpdateFacility(facilityValues, {
      onSuccess: (data) => {
        const facilityDetails = {
          ...data,
          _type: 'generic',
        }

        callback(facilityDetails)
        queryClient.refetchQueries({
          queryKey: ['getFacilityDetails', facilityDetails.uuid],
        })

        setIsSubmitting(false)
        resetForm()
        handleCloseDrawer()

        toast.info('Facility updated.')
      },
    })
  }

  const handleFormSubmit = () => {
    setIsSubmitting(true)
    analytics.track(AnalyticsEvent.FacilityManagementSave)

    const {
      company_name,
      facility_address,
      mode,
      operating_time_from,
      operating_time_to,
      notes,
    } = values

    const facilityValues = {
      company_name,
      address: facility_address?.address,
      zipcode: facility_address?.zipcode,
      city: facility_address?.city,
      state: facility_address?.state,
      country: facility_address?.country,
      hours_of_operations: {
        mode,
        opens: operating_time_from.format('HH:mm'),
        closes: operating_time_to.format('HH:mm'),
        ...buildOperatingDays(),
      },
      ...(notes
        ? {
            notes: [
              {
                description: notes,
              },
            ],
          }
        : { notes: [] }),
      warehouse_uuid: warehouse_uuid || undefined,
    }

    if (facilityUUID) {
      return customMutateUpdateFacility({
        facilityUUID,
        facility: facilityValues,
      })
    }

    return customMutateNewFacility(facilityValues)
  }

  const handleFacilityError = (): string => {
    if (!touched.facility_address || !errors.facility_address) {
      return ''
    }

    if (
      typeof errors.facility_address === 'object' &&
      values.facility_address
    ) {
      const facilityErrors = Object.values(errors.facility_address)

      if (facilityErrors.length > 0) {
        return facilityErrors[0]
      }

      return ''
    }

    return REQUIRED_FIELD_MESSAGE
  }

  const facilityAddressError = handleFacilityError()

  const onCloseDrawer = () => {
    resetForm()
    handleCloseDrawer()
  }

  const handleAdressSelectChange = (
    fieldName: string,
    fieldValue: FieldSelect
  ) => {
    if (fieldValue) {
      setFieldValue(`facility_address.${fieldName}`, fieldValue.value)
      setFieldTouched(`facility_address.${fieldName}`)
      // if we already have a country selected and change it, clear the state field
      if (fieldName === 'country' && values.facility_address?.country) {
        setFieldValue(`facility_address.state`, null)
      }
    }
  }

  useEffect(() => {
    if (isOpen && formikProps?.validateOnMount) {
      validateForm()
    }
  }, [isOpen, validateForm, formikProps?.validateOnMount])

  if (!isOpen) {
    return null
  }

  const states = getStates(values.facility_address?.country)
  const selectedCountry = getSelectedCountry(values.facility_address?.country)
  const selectedState = getSelectedState(values.facility_address?.state)
  const actionLabel = formatActionLabel(facilityUUID)

  return (
    <FormikProvider value={formControl}>
      <Drawer
        open={isOpen}
        size="medium"
        onClose={onCloseDrawer}
        onClosed={onClosed}
        data-testid="facility-drawer"
      >
        <Drawer.Header role="heading">
          {title}
          <Drawer.Close aria-label="Close facility drawer" />
        </Drawer.Header>

        <Drawer.Body>
          <Layout.Stack>
            <FacilityAddressField addressError={facilityAddressError} />
            <Layout.Grid>
              <Field label="Zipcode" required style={{ flex: 1 }}>
                <TextField
                  id="facility_address.zipcode"
                  type="text"
                  aria-label="zipcode"
                  value={values.facility_address?.zipcode ?? ''}
                  onInput={(event) => {
                    setFieldValue(
                      'facility_address.zipcode',
                      event.target.value
                    )
                    setFieldTouched('facility_address.zipcode')
                  }}
                />
              </Field>
              <Field label="City" required style={{ flex: 1 }}>
                <TextField
                  id="facility_address.city"
                  type="text"
                  aria-label="city"
                  value={values.facility_address?.city ?? ''}
                  onInput={(event) => {
                    setFieldValue('facility_address.city', event.target.value)
                    setFieldTouched('facility_address.city')
                  }}
                />
              </Field>
            </Layout.Grid>
            <Layout.Grid>
              <Select
                required
                space="xs"
                label="State"
                name="facility_address.state"
                id="facility_address.state"
                options={states}
                onChange={(event) => {
                  handleAdressSelectChange(
                    'state',
                    event.target.value as FieldSelect
                  )
                }}
                value={
                  {
                    _type: '',
                    label: selectedState?.label ?? '',
                    value: values.facility_address?.state ?? '',
                  } as FieldSelect
                }
                hideClear
              />
              <Select
                required
                space="xs"
                label="Country"
                name="facility_address.country"
                id="facility_address.country"
                options={countries}
                onChange={(event) => {
                  handleAdressSelectChange(
                    'country',
                    event.target.value as FieldSelect
                  )
                }}
                value={
                  {
                    _type: '',
                    label: selectedCountry?.label ?? '',
                    value: values.facility_address?.country ?? '',
                  } as FieldSelect
                }
                components={{ Option: CountryOption }}
                hideClear
              />
            </Layout.Grid>

            <Layout.Group align="flex-start">
              <FormField
                label="Facility name"
                name="company_name"
                placeholder="Insert name"
                value={values.company_name}
                handleChange={(event) => {
                  handleChange(event)
                  setFieldTouched('company_name')
                }}
                touched={!!touched.company_name}
                error={errors.company_name}
                trailing={<IconAttention width={12} height={12} />}
                span={2}
                required
                style={{ flex: 1 }}
              />

              <FormField
                label="Facility type"
                name="mode"
                touched={!!touched.mode}
                handleFieldTouched={() => {
                  setFieldTouched('mode')
                }}
                error={errors.mode}
                component={
                  <ToggleGroup
                    type="single-strict"
                    onChange={(event) => {
                      const mode = get(event, 'target.value')

                      setFieldTouched('mode')

                      setFieldValue('mode', mode)
                    }}
                    onBlur={() => {
                      setFieldTouched('mode')
                    }}
                    value={values.mode}
                    options={[
                      {
                        label: 'APPT',
                        value: 'APPT',
                      },
                      {
                        label: 'FCFS',
                        value: 'FCFS',
                      },
                    ]}
                  />
                }
              />
            </Layout.Group>

            <WarehouseSection facility={facilityData} />

            <ScheduleWrapper>
              <Layout.Stack gap="spacing-0-5">
                <Layout.Group align="center" gap="spacing-3">
                  <FormField
                    label="Days"
                    name="operating_days"
                    touched={!!touched.operating_days}
                    error={errors.operating_days}
                    required
                    component={
                      <ToggleGroup
                        type="multiple"
                        onChange={(event) => {
                          const days = get(event, 'target.value')

                          setFieldTouched('operating_days')
                          if (days) {
                            setFieldValue('operating_days', days)
                          } else {
                            setFieldValue('operating_days', undefined)
                          }
                        }}
                        onBlur={() => {
                          setFieldTouched('operating_days')
                        }}
                        value={values.operating_days}
                        options={daysOptions}
                      />
                    }
                  />

                  <Layout.Group align="baseline" gap="spacing-2">
                    <FormField
                      label="Open"
                      name="operating_time_from"
                      touched={!!touched.operating_time_from}
                      component={
                        <TimeInput
                          name="operating_time_from"
                          data-testid="operating_time_from"
                          type="time"
                          value={values.operating_time_from.format('HH:mm')}
                          onChange={(event) => {
                            const time = event.target.value

                            if (time) {
                              const value = setMomentTime(
                                values.operating_time_from,
                                time
                              )
                              setFieldValue('operating_time_from', value)
                              setFieldTouched('operating_time_from')
                            }
                          }}
                        />
                      }
                    />

                    <FormField
                      label="Close"
                      name="operating_time_to"
                      touched={!!touched.operating_time_to}
                      component={
                        <TimeInput
                          name="operating_time_to"
                          data-testid="operating_time_to"
                          type="time"
                          value={values.operating_time_to.format('HH:mm')}
                          onChange={(event) => {
                            const time = event.target.value

                            if (time) {
                              const value = setMomentTime(
                                values.operating_time_to,
                                time
                              )
                              setFieldValue('operating_time_to', value)
                              setFieldTouched('operating_time_to')
                            }
                          }}
                        />
                      }
                    />
                  </Layout.Group>
                </Layout.Group>
              </Layout.Stack>
            </ScheduleWrapper>

            <ExpandableTextarea
              space="xs"
              label="Facility default instructions (you'll be able to edit it for any shipment)"
              name="notes"
              id="notes"
              rows={10}
            />
          </Layout.Stack>
        </Drawer.Body>

        <Drawer.Actions>
          <Drawer.ActionTertiary onClick={onCloseDrawer}>
            Close
          </Drawer.ActionTertiary>
          <Drawer.ActionPrimary
            disabled={!canSubmit}
            onClick={() => handleFormSubmit()}
          >
            {actionLabel}
          </Drawer.ActionPrimary>
        </Drawer.Actions>
      </Drawer>
    </FormikProvider>
  )
}
