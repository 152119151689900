import { IconTrash } from '@loadsmart/icons'
import { Button, Card, Layout, Table, Text } from '@loadsmart/miranda-react'
import type { FormikErrors } from 'formik'
import { useFormik } from 'formik'
import type { ChangeEvent } from 'react'
import * as Yup from 'yup'

import { FormField } from 'components/FormField'
import { Phone } from 'components/Input'
import { REQUIRED_FIELD_MESSAGE } from 'constants/errors'
import { EMAIL_MAX_LENGTH } from 'utils/constants'

interface Contact {
  contact_uuid?: string
  contact_name?: string
  contact_phone: string
  contact_email?: string
  contact_ext?: string
}

export interface SchedulingContactsProps {
  readonly contacts: Array<Contact>
  readonly setFieldValue: (
    field: string,
    value: Array<Contact>,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<any>>
  readonly onSaveContact?: (contact: Contact) => void
  readonly onDeleteContact?: (contactUUID: string) => void
}

const initialValues: Contact = {
  contact_uuid: '',
  contact_name: '',
  contact_phone: '',
  contact_email: undefined,
  contact_ext: '',
}

const validationSchema = Yup.object().shape({
  contact_name: Yup.string(),
  contact_phone: Yup.string()
    .matches(/\(\d{3}\)\s\d{3}-\d{4}/, 'Must be a valid phone number')
    .required(REQUIRED_FIELD_MESSAGE),
  contact_email: Yup.string()
    .email('Must be a valid email.')
    .max(EMAIL_MAX_LENGTH, 'Email is too long.'),
  ext: Yup.string(),
})

export default function SchedulingContacts({
  contacts,
  setFieldValue,
  onSaveContact,
  onDeleteContact,
}: SchedulingContactsProps) {
  const { values, touched, errors, handleChange, setFieldTouched, resetForm } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: () => undefined,
    })

  const handleFieldTouched = (name: string) => {
    setFieldTouched(name, true)
  }

  const handleSubmit = () => {
    const contact = {
      contact_uuid: values.contact_uuid,
      contact_name: values.contact_name,
      contact_phone: values.contact_phone,
      contact_ext: values.contact_ext,
      contact_email: values.contact_email,
    }

    setFieldValue('contacts', [...contacts, contact])

    if (onSaveContact) {
      onSaveContact(contact)
    }

    resetForm()
  }

  const handleRemoveContact = (contact: Contact) => {
    const newContacts = contacts.filter(
      (c) => c.contact_email !== contact.contact_email
    )
    setFieldValue('contacts', newContacts)

    if (onDeleteContact && contact.contact_uuid) {
      onDeleteContact(contact.contact_uuid)
    }
  }

  const getContactInfo = (contact: Contact) => {
    const getEXT = contact.contact_ext && `EXT ${contact.contact_ext}`
    const email = contact.contact_email ? `/ ${contact.contact_email}` : ''
    return `${contact.contact_phone} ${getEXT} ${email}`
  }

  const hasError = Object.keys(errors).length > 0
  const hasTouched = Object.keys(touched).length > 0
  const canSubmit = !hasError && hasTouched

  return (
    <>
      {contacts.length ? (
        <Table aria-label="Facility contacts">
          <Table.Head>
            <Table.Row>
              <Table.Cell>Contact name</Table.Cell>
              <Table.Cell>Phone number/Email</Table.Cell>
              <Table.Cell />
            </Table.Row>
          </Table.Head>

          <Table.Body>
            {contacts.map((contact) => (
              <Table.Row key={contact.contact_phone}>
                <Table.Cell>
                  <Text variant="body-md-bold">
                    {contact.contact_name || '-'}
                  </Text>
                </Table.Cell>
                <Table.Cell>
                  <Text variant="body-md">{getContactInfo(contact)}</Text>
                </Table.Cell>
                <Table.Cell>
                  <Button
                    aria-label="Remove contact"
                    variant="icon"
                    size="small"
                    onClick={() => {
                      handleRemoveContact(contact)
                    }}
                  >
                    <IconTrash width={12} height={12} title={null} />
                  </Button>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      ) : null}

      <Card>
        <Card.Title>New contact</Card.Title>

        <Card.Divider />

        <Card.Body>
          <Layout.Stack gap="spacing-6">
            <form>
              <Layout.Stack>
                <Layout.Group>
                  <FormField
                    style={{ flex: 1 }}
                    label="Contact name"
                    name="contact_name"
                    placeholder="e.g.: John Smith"
                    value={values.contact_name}
                    handleFieldTouched={() =>
                      handleFieldTouched('contact_name')
                    }
                    handleChange={(event) => handleChange(event)}
                    touched={!!touched.contact_name}
                    span={2}
                  />
                </Layout.Group>
                <Layout.Group>
                  <FormField
                    style={{ flex: 1 }}
                    label="Phone number"
                    name="contact_phone"
                    touched={!!touched.contact_phone}
                    error={errors.contact_phone}
                    span={2}
                    required
                    component={
                      <Phone
                        name="contact_phone"
                        aria-label="Contact phone"
                        placeholder="(       )        -"
                        guide={false}
                        value={values.contact_phone}
                        onChange={(event: ChangeEvent) => handleChange(event)}
                        onBlur={() => handleFieldTouched('contact_phone')}
                      />
                    }
                  />
                  <FormField
                    style={{ flex: 1 }}
                    label="Phone extension"
                    name="contact_ext"
                    placeholder="00"
                    value={values.contact_ext}
                    handleFieldTouched={() => handleFieldTouched('contact_ext')}
                    handleChange={(event) => handleChange(event)}
                    touched={!!touched.contact_ext}
                    span={2}
                  />

                  <FormField
                    style={{ flex: 1 }}
                    label="E-mail"
                    name="contact_email"
                    placeholder="contact@facility.co"
                    value={values.contact_email}
                    handleFieldTouched={() =>
                      handleFieldTouched('contact_email')
                    }
                    handleChange={(event) => handleChange(event)}
                    touched={!!touched.contact_email}
                    error={errors.contact_email}
                    span={2}
                  />
                </Layout.Group>
                <Layout.Group justify="flex-start">
                  <Button
                    variant="primary"
                    disabled={!canSubmit}
                    onClick={handleSubmit}
                  >
                    Save contact
                  </Button>
                </Layout.Group>
              </Layout.Stack>
            </form>
          </Layout.Stack>
        </Card.Body>
      </Card>
    </>
  )
}
