import { useMemo, useRef } from 'react'
import type {
  QueryFunctionContext,
  QueryObserverOptions,
  UseQueryResult,
} from 'react-query'
import { useQueries, useQuery } from 'react-query'

import type { FacilityDetailsV2 } from 'services/facilities'
import { getFacilityDetails } from 'services/facilities'

export function useFacilityDetailsV2(
  facilityUUID?: string | null,
  options?: QueryObserverOptions<FacilityDetailsV2>
): UseQueryResult<FacilityDetailsV2> {
  return useQuery({
    queryKey: ['getFacilityDetailsV2', facilityUUID],
    queryFn() {
      return getFacilityDetails(facilityUUID!)
    },
    enabled: facilityUUID != null,
    refetchInterval: false,
    staleTime: 0,
    retry: false,
    refetchOnWindowFocus: false,
    ...options,
  })
}

export function useMultipleFacilityDetailsV2(
  uuids: string[],
  options?: QueryObserverOptions<FacilityDetailsV2>
) {
  /**
   * There will a "combine" option in react-query v5 (Source: https://github.com/TanStack/query/issues/5137#issuecomment-1547390616)
   * In the meantime, we can use the following easy "hack" to memoize the queries result (Source: https://github.com/TanStack/query/issues/5137#issuecomment-2199989432)
   */
  const successfulQueriesCounter = useRef(0)
  const queries = useQueries(
    uuids.map((uuid) => ({
      queryKey: ['getFacilityDetailsV2', uuid],
      staleTime: 0,
      refetchOnWindowFocus: false,
      retry: 3,
      ...options,
      queryFn: ({ signal }: QueryFunctionContext) => {
        return getFacilityDetails(uuid, { signal })
      },
      onSuccess: () =>
        (successfulQueriesCounter.current =
          successfulQueriesCounter.current + 1),
    }))
  )

  const result = useMemo(() => {
    if (!queries) {
      return undefined
    }
    const dataArray = []

    for (const query of queries) {
      dataArray.push(query.data)
    }

    return dataArray
  }, [successfulQueriesCounter.current])

  return result
}
