import { Banner, Drawer, Icon, Layout, Text } from '@loadsmart/miranda-react'
import { get, set } from 'lodash'
import { useCallback, useState } from 'react'
import { toast } from 'react-toastify'

import { FacilityContactsFormCard as ContactsSection } from 'components/FacilityContactsForm/FacilityContactsFormCard'
import { useSettings } from 'contexts/settings'
import type { FacilityDetailsV2 } from 'services/facilities'
import analytics, {
  AnalyticsEvent,
  AnalyticsEventTrigger,
} from 'utils/analytics'
import { scrollToTop } from 'utils/scroll'
import toArray from 'utils/toArray'
import { hasTransientError } from 'utils/transient'

import { AccessorialsSection } from './AccessorialsSection'
import {
  createTransientFacilityV2,
  validateTransientFacility,
} from './Facility.helpers'
import type { TransientFacilityV2 } from './Facility.types'
import { InstructionsSection } from './InstructionsSection'
import { LocationSection } from './LocationSection'
import { OperatingHoursSection } from './OperatingHoursSection'
import { useCreateNewFacility } from './useCreateNewFacility'

export type CreateFacilityDrawerProps = {
  readonly isOpen: boolean
  readonly onClose: () => void
  readonly onClosed: () => void
  readonly initialValues?: Partial<TransientFacilityV2>
  readonly onFacilitySaved?: (facility: FacilityDetailsV2) => void
}

type ErrorState = {
  title: string
  errors: string[]
}

function hasServerErrors(state?: ErrorState | null): state is ErrorState {
  if (state?.errors == null) {
    return false
  }

  return Boolean(state.title) || state.errors.length > 0
}

export function CreateFacilityDrawer({
  initialValues,
  isOpen,
  onClose,
  onClosed,
  onFacilitySaved,
}: CreateFacilityDrawerProps) {
  const {
    values: [facilityAccessorialsEnabled],
  } = useSettings(['flags.ENABLE_FACILITY_ACCESSORIALS'])
  const [hasSubmitted, setHasSubmitted] = useState(false)
  const [errorState, setErrorState] = useState<ErrorState | null>()
  const [facility, setFacility] = useState<TransientFacilityV2>(() =>
    createTransientFacilityV2(initialValues)
  )

  const { createFacility, isSaving } = useCreateNewFacility({
    onSuccess: (savedFacility) => {
      onFacilitySaved?.(savedFacility)
      toast.success('Facility created')

      // clear state for next use
      setHasSubmitted(false)
      setErrorState(null)
      setFacility(createTransientFacilityV2({}))

      analytics.track(
        AnalyticsEvent.FacilityManagementFacilityCreated,
        AnalyticsEventTrigger.click,
        {
          place: 'drawer',
          has_contacts: toArray(savedFacility.contacts).length > 0,
          has_instructions: toArray(savedFacility.notes).length > 0,
          instructions_per_mode: false, // this feature has not been implemented yet
          hours_per_mode: false, // this feature has not been implemented yet
        }
      )

      onClose()
    },
    onError: (errors) => {
      setErrorState({
        title:
          errors.length > 0
            ? 'Please fix the errors below to proceed'
            : 'Failed to save facility, try again later',
        errors,
      })

      scrollToTop('.edit-contacts-drawer-body')
    },
  })

  const onSetFacility = useCallback(
    (changes: Partial<TransientFacilityV2>) => {
      setFacility((currentFacility) => {
        const updatedFacility = Object.keys(changes).reduce(
          (newFacility, path) => {
            return set(newFacility, path, get(changes, path))
          },
          { ...currentFacility }
        )

        // If the user has already submitted the form before, run validations when values change
        if (hasSubmitted) {
          const [validatedFacility] = validateTransientFacility(updatedFacility)

          return validatedFacility
        }

        return updatedFacility
      })
    },
    [hasSubmitted]
  )

  const onSaveClick = useCallback(() => {
    setHasSubmitted(true)
    setErrorState(null)

    const [newFacility, isValid] = validateTransientFacility(facility)

    setFacility(newFacility)

    if (isValid) {
      createFacility(newFacility)
      scrollToTop()
    } else {
      scrollToTop('#create-facility-drawer-body')
    }
  }, [createFacility, facility])

  return (
    <Drawer
      open={isOpen}
      size="large"
      divided
      onClose={onClose}
      onClosed={onClosed}
      data-testid="create-facility-drawer"
    >
      <Drawer.Header role="heading" aria-level={1}>
        New facility
        <Drawer.Close aria-label="Close new facility drawer" />
      </Drawer.Header>

      <Drawer.Body id="create-facility-drawer-body">
        <Layout.Stack gap="spacing-6">
          {hasSubmitted && hasTransientError(facility) && (
            <Banner variant="danger">
              <Banner.Title>
                Please fix the errors below to proceed
              </Banner.Title>
            </Banner>
          )}

          {hasServerErrors(errorState) && (
            <Banner variant="danger">
              <Banner.Title>{errorState.title}</Banner.Title>
              <Banner.Description>
                <Layout.Stack gap="none">
                  {errorState.errors.map((error) => (
                    <Text key={error} color="color-text-secondary">
                      {error}
                    </Text>
                  ))}
                </Layout.Stack>
              </Banner.Description>
            </Banner>
          )}

          <LocationSection
            collapsible
            initialCollapsed={false}
            location={facility.location}
            onChange={(newLocation) => {
              onSetFacility({
                location: newLocation,
              })
            }}
          />
          <ContactsSection
            collapsible
            initialCollapsed
            contacts={facility.contacts}
            onChange={(newContacts) => {
              onSetFacility({
                contacts: newContacts,
              })
            }}
          />
          <OperatingHoursSection
            collapsible
            initialCollapsed
            weeklyOperatingHours={facility.hours_of_operation}
            onChange={(newWeeklyOperatingHours) => {
              onSetFacility({
                hours_of_operation: newWeeklyOperatingHours,
              })
            }}
          />
          <InstructionsSection
            collapsible
            initialCollapsed
            instruction={get(facility, 'notes.0')}
            onChange={(newNote) => {
              onSetFacility({
                notes: [newNote],
              })
            }}
          />
          {facilityAccessorialsEnabled && (
            <AccessorialsSection
              collapsible
              initialCollapsed
              accessorials={facility.accessorials}
              onChange={(newAccessorials) => {
                onSetFacility({ accessorials: newAccessorials })
              }}
            />
          )}
        </Layout.Stack>
      </Drawer.Body>

      <Drawer.Actions justify="flex-start">
        <Drawer.ActionTertiary
          leading={<Icon name="arrow-sort-up" />}
          onClick={() => {
            scrollToTop('#create-facility-drawer-body')
          }}
        >
          Back to top
        </Drawer.ActionTertiary>
        <Drawer.ActionTertiary
          disabled={isSaving}
          onClick={onClose}
          style={{ marginLeft: 'auto' }}
        >
          Cancel
        </Drawer.ActionTertiary>
        <Drawer.ActionPrimary
          disabled={isSaving}
          loading={isSaving}
          onClick={onSaveClick}
        >
          Create facility
        </Drawer.ActionPrimary>
      </Drawer.Actions>
    </Drawer>
  )
}
