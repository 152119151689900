import type { DatePickerProps as MDatePickerProps } from '@loadsmart/loadsmart-ui'
import { DatePicker as MDatePicker } from '@loadsmart/loadsmart-ui'
import type EventLike from '@loadsmart/loadsmart-ui/dist/utils/types/EventLike'

import { BaseField } from './BaseField'
import type { CustomFormikFieldProps } from './CustomFormikField'
import { CustomFormikField } from './CustomFormikField'

export interface DatePickerProps
  extends Omit<
      MDatePickerProps,
      'name' | 'value' | 'form' | 'className' | 'id'
    >,
    Omit<CustomFormikFieldProps, 'children'> {}

export function DatePicker({ ...props }: DatePickerProps) {
  return (
    <CustomFormikField {...props}>
      {({
        field: { onChange: fieldOnChange, ...field },
        baseFieldProps,
        baseProps,
        form: { setFieldTouched },
      }) => {
        const mappedProps = {
          onChange: (event: EventLike<string>) => {
            fieldOnChange(event)
            setFieldTouched(field.name, true, false)
          },
          ...field,
          ...baseProps,
        }

        return (
          <BaseField {...baseFieldProps}>
            <MDatePicker {...mappedProps} />
          </BaseField>
        )
      }}
    </CustomFormikField>
  )
}
