import type { AxiosRequestConfig } from 'axios'

import type { CommodityPackageType } from 'components/ShippingItemsManager'
import type { ShipmentChargeStatus } from 'screens/Shipper/Shipments/Details/types'
import type {
  BulkTenderAvailabilityResponse,
  BulkTenderResponse,
  BulkRequestSchedulingAvailabilityResponse,
} from 'screens/Shipper/Shipments/ShipmentList/types'
import { requestSchedulingMapper } from 'screens/Shipper/Shipments/ShipmentList/utils'
import { cleanEmptyValues } from 'utils/cleanEmptyValues'
import httpClient from 'utils/httpClient'
import type { HazmatPackingGroup, HazmatClass } from 'utils/types'

import type { SelectableLocation } from '../components/FiltersDrawer'

export interface DryRunResult {
  unavailable: number
  available: ActionDryRunAvailable
}

export interface ActionDryRunAvailable {
  status: { [id: string]: number }
  results: string[]
}

export interface ShipmentsListRequest {
  filters?: ShipmentsListFilters
  offset: number
  limit: number
  sort?: string[]
}

export interface ShipmentsListFilters {
  auto_tender_status?: string[]
  booked_after?: string
  carrier?: string[]
  charges_status?: ShipmentChargeStatus[]
  container_direction?: string[]
  created_at_after?: string
  created_at_before?: string
  delivery_date_after?: string
  delivery_date_before?: string
  delivery_location?: SelectableLocation[]
  equipment_type?: string[]
  tracking_status?: string[]
  excluded_status?: string[]
  mode?: string[]
  out_of_time?: string
  out_of_time_delivery?: string
  out_of_time_pickup?: string
  pending_schedule?: string
  pending_schedule_delivery?: string
  pending_schedule_pickup?: string
  pickup_date_after?: string
  pickup_date_before?: string
  pickup_location?: SelectableLocation[]
  rate_types?: string[]
  search?: string
  source?: string[]
  status?: string[]
  tender_rejected_after?: string
  needs_attention?: string
  tags?: string[]
  excluded_tags?: string[]
}

export type ShipmentsListSort = { column: string; direction: string } | null

export type ShipmentsListFiltersArrays = Pick<
  ShipmentsListFilters,
  | 'status'
  | 'mode'
  | 'equipment_type'
  | 'rate_types'
  | 'container_direction'
  | 'source'
  | 'auto_tender_status'
>

export type ExcessiveLengthClassification =
  | 'excessive_length_8ft'
  | 'excessive_length_12ft'
  | 'excessive_length_16ft'
  | 'excessive_length_20ft'

export type NewShipmentShippingItemPayload = {
  package_count: number
  height: number
  width: number
  length: number
  package_type: string | null
  stackable: boolean
  turnable: boolean
  ltl_excessive_length_class?: ExcessiveLengthClassification
  order_uuids?: string[]
  order_items?: NewShipmentOrderItemPayload[]
  commodities: NewShipmentCommodityPayload[]
  pickup_stop_index: number
  delivery_stop_index: number

  //Fields to keep backwards compatibility
  commodity?: string
  hazmat?: boolean
  total_volume?: number
  weight?: number
  freight_class?: string | null
  nmfc_code?: string | null
  hazmat_package_quantity?: string
  hazmat_package_type?: CommodityPackageType | null
  hazmat_shipping_name?: string
  hazmat_un_number?: string
  hazmat_class?: HazmatClass | null
  hazmat_packing_group?: HazmatPackingGroup | null
}

export type NewShipmentOrderItemPayload = {
  uuid: string
  shipped_package_count?: number
}

export type NewShipmentCommodityPayload = {
  description: string
  package_count: number
  package_type: string | null
  weight: number
  freight_class: string | null
  nmfc_code: string | null
  hazmat_package_quantity?: string
  hazmat_package_type?: CommodityPackageType | null
  hazmat_shipping_name?: string
  hazmat_un_number?: string
  hazmat_class?: HazmatClass | null
  hazmat_packing_group?: HazmatPackingGroup | null
}

export type NewShipmentStopPayload = {
  stop_type: StopType | null
  stop_index: number
  facility_uuid: string | null
  date: string | null
  notes: string | null
  update_facility_notes: boolean
  default_contact_uuid: string | null
}

// TODO: Add the missing types for FTL/IMDL shipments
export type NewShipmentPayload = {
  po_numbers: string[] | null
  bol_number: string | null
  so_numbers: string[] | null
  shipper_ref_number: string | null
  // Use the mode abbr
  mode: string
  // Use the equipment type abbr
  equipment_type: string
  equipment_length: string | null
  equipment_requirements?: {
    temperature: number | string | null
    unit?: string | null
  }
  equipment_subtypes?: string[]
  with_tarp?: boolean
  tarp_size?: number
  tarp_type?: string
  accessorials?: string[]
  requested_accessorials?: Pick<PriceItemType, 'uuid' | 'stops_relationship'>[]
  items: NewShipmentShippingItemPayload[]
  stops: NewShipmentStopPayload[]
  hazmat: boolean
  hazmat_contact_name?: string | null
  hazmat_phone_number?: string | null
  power_only: boolean
  add_stop_off_charges?: boolean
}

export type CheckTagsPayload = {
  shipment_uuids?: string[]
  filters?: ShipmentsListFilters
  all?: boolean
}

export type CheckTag = {
  tag: {
    uuid: string
    name: string
  }
  shipment_count: number
  shipment_uuids: string[]
}

export type BulkManageTagsPayload = {
  shipment_uuids?: string[]
  filters?: ShipmentsListFilters
  all?: boolean
  tags: string[]
  action: 'assign' | 'unassign'
}

export const createNewShipment = async (shipment: NewShipmentPayload) => {
  const { data } = await httpClient.post<{ uuid: string }>(
    '/shipments',
    shipment
  )

  return data
}

export const createFacility = async (facility: unknown) => {
  const { data } = await httpClient.post<FacilityDetails>(
    '/shippers/facilities',
    facility
  )
  return data
}

export const updateFacility = async ({
  facilityUUID,
  facility,
}: {
  facilityUUID: string
  facility: unknown
}) => {
  const { data } = await httpClient.put(
    `/shippers/facilities/${facilityUUID}`,
    facility
  )
  return data
}

export const deleteFacility = async (facilityUUID: string) => {
  const { data } = await httpClient.delete(
    `/shippers/facilities/${facilityUUID}`
  )
  return data
}

export const createContact = async ({
  facilityUUID,
  contact,
}: {
  facilityUUID: string
  contact: any
}) => {
  const { data } = await httpClient.post(
    `/shippers/facilities/${facilityUUID}/contacts`,
    contact
  )
  return data
}

export const deleteContact = async ({
  facilityUUID,
  contactUUID,
}: {
  facilityUUID: string
  contactUUID: string
}) => {
  const { data } = await httpClient.delete(
    `/shippers/facilities/${facilityUUID}/contacts/${contactUUID}`
  )
  return data
}

export const getShipperFacilities = async <T>(
  params?: Record<string, unknown>,
  options?: AxiosRequestConfig
) => {
  const { data } = await httpClient.get('/shippers/facilities', {
    params,
    ...options,
  })

  return data as T[]
}

export const getFacilities = async <T>(
  params?: Record<string, unknown>,
  options?: AxiosRequestConfig
) => {
  const { data } = await httpClient.get('/facilities', {
    params,
    ...options,
  })

  return data as T[]
}

export const getFacilityDetails = async <T>(
  facilityUUID: string,
  options?: AxiosRequestConfig
) => {
  const { data } = await httpClient.get(
    `/shippers/facilities/${facilityUUID}`,
    options
  )
  return data as T
}

export const getShipmentLocations = async (
  uuid: string,
  offset = 0,
  limit = 20
) => {
  const { data } = await httpClient.get(`/shipments/${uuid}/locations`, {
    params: {
      limit,
      offset,
    },
  })
  return data
}

export type ShipmentLocations = {
  data: ShipmentLocation[]
  count: number
  next: string | null
  previous: string | null
}

export const getPublicShipmentLocations = async ({
  shipmentUUID,
  limit,
  offset,
}: {
  shipmentUUID: string
  limit: number
  offset: number
}) => {
  const { data } = await httpClient.get<ShipmentLocations>(
    `/shipments/${shipmentUUID}/public-locations?limit=${limit}&offset=${offset}`
  )
  return data
}

export const listShipments = async (
  limit: number,
  offset: number,
  sort: ShipmentsListSort,
  filters: ShipmentsListFilters | undefined
) => {
  const { data } = await httpClient.post(`shipments/list`, {
    limit,
    offset,
    sort: sort?.column
      .split(',')
      .map((column) => (sort.direction === 'desc' ? `-${column}` : column)),
    filters: cleanEmptyValues(filters || {}),
  })

  return data
}

export const getShipmentListMetadata = async () => {
  const { data } = await httpClient.get('/shipments/metadata')
  return data
}

export const bulkDeleteShipmentsDryRun = async (options: {
  shipments: 'all' | string[]
  filters: ShipmentsListFilters | undefined
}) => {
  return await httpClient.post<DryRunResult>('shipments/bulk-delete/dry-run', {
    ...(options.shipments === 'all'
      ? { all: true, filters: cleanEmptyValues(options.filters || {}) }
      : { shipments: options.shipments }),
  })
}

export const bulkDeleteShipment = async (shipments: string[]) => {
  return await httpClient.post(`shipments/bulk-delete`, {
    shipments,
  })
}

export const deleteShipment = async (shipmentUUID: string) => {
  const { data } = await httpClient.delete(`shipments/${shipmentUUID}`)
  return data
}

export const ARCHIVE_SHIPMENT_URL = 'shipments/archive'
export const archiveShipments = async (
  shipmentsUUIDs: string[],
  filters?: ShipmentsListFilters,
  applyToAllShipments = false
) => {
  const { data } = await httpClient.post(ARCHIVE_SHIPMENT_URL, {
    shipments: shipmentsUUIDs,
    all: applyToAllShipments,
    filters: cleanEmptyValues(filters ?? {}),
  })
  return data
}

export const UNARCHIVE_SHIPMENT_URL = 'shipments/unarchive'
export const unarchiveShipments = async (
  shipmentsUUIDs: string[],
  filters?: ShipmentsListFilters,
  applyToAllShipments = false
) => {
  const { data } = await httpClient.post(UNARCHIVE_SHIPMENT_URL, {
    shipments: shipmentsUUIDs,
    all: applyToAllShipments,
    filters: cleanEmptyValues(filters ?? {}),
  })
  return data
}

export const DUPLICATE_SHIPMENT_URL = `shipments/copy`
export const duplicateShipment = async (shipmentToDuplicate: {
  uuid: string
  pickupDate: string
  deliveryDate: string
}) => {
  const duplicateShipmentRequestData = {
    shipment_uuid: shipmentToDuplicate.uuid,
    pickup_date: shipmentToDuplicate.pickupDate,
    delivery_date: shipmentToDuplicate.deliveryDate,
  }
  const response = await httpClient.post(
    DUPLICATE_SHIPMENT_URL,
    duplicateShipmentRequestData
  )
  return response.data
}

export const cancelShipment = async ({
  shipmentUUID,
  cancelReason,
}: {
  shipmentUUID: string
  cancelReason: string
}) => {
  const response = await httpClient.post(`/shipments/${shipmentUUID}/cancel`, {
    cancel_reason: cancelReason,
  })
  return response.data
}

export const updateShipmentDetails = (
  shipmentUUID: string,
  shipment: unknown
) => {
  return httpClient.patch(`shipments/${shipmentUUID}`, shipment)
}

export const handleAction = async ({
  method,
  endpoint,
  payload,
}: {
  method: string
  endpoint: string
  payload?: { [key: string]: any } | null
}) => {
  if (method === 'POST') {
    const { data } = await httpClient.post(endpoint, payload)
    return data
  }
  if (method === 'GET') {
    const { data } = await httpClient.get(endpoint)
    return data
  }
  if (method === 'DELETE') {
    const { data } = await httpClient.delete(endpoint)
    return data
  }
  if (method === 'PATCH') {
    const { data } = await httpClient.patch(endpoint)
    return data
  }
}

export const removeFile = async ({
  uuid,
  fileID,
}: {
  uuid: string
  fileID: string
}) => {
  const { data } = await httpClient.delete(
    `spot_quotes/${uuid}/attachments/${fileID}`
  )
  return data
}

export const cancelTender = async ({
  shipmentUUID,
  laneProposalID,
}: {
  shipmentUUID: string
  laneProposalID: number
}) => {
  const { data } = await httpClient.patch(
    `shipments/${shipmentUUID}/cancel_tender`,
    {
      lane_proposal_id: laneProposalID,
    }
  )
  return data
}

export const getPublicShipmentStatus = async (shipmentUUID: string) => {
  const { data } = await httpClient.get(
    `shipments/public-shipment-status/${shipmentUUID}`
  )

  return data
}

export const getBOL = async (shipmentUUID?: string) => {
  const { data } = await httpClient.get(
    `shipments/${shipmentUUID}/bill_of_lading.pdf`,
    {
      responseType: 'blob',
    }
  )
  return data
}

export const mountShippingLabelURL = (shipmentUUID: string) => {
  return `shipments/${shipmentUUID}/shipping_label.pdf`
}

export const getShippingLabel = async (shipmentUUID: string) => {
  const { data } = await httpClient.get(mountShippingLabelURL(shipmentUUID), {
    responseType: 'blob',
  })

  return data
}

export const checkShippingLabelAvailability = async (shipmentUUID: string) => {
  try {
    await httpClient.head(mountShippingLabelURL(shipmentUUID))

    return true
  } catch {
    return false
  }
}

export const createQuoteFromShipment = async (shipmentUUID: string) => {
  const { data } = await httpClient.post(
    `/shipments/${shipmentUUID}/spot-quote`
  )
  return data
}

export const bulkCreateQuotesFromShipment = async ({
  shipments,
  mode,
  is_async,
}: {
  shipments: string[]
  mode: string
  is_async?: boolean
}) => {
  const { data } = await httpClient.post('/bulk/quotes-from-shipments', {
    shipments,
    mode,
    is_async,
  })
  return data
}

export const getReceipt = async (
  shipmentUUID?: string,
  config?: AxiosRequestConfig
) => {
  const { data } = await httpClient.get(
    `shipments/${shipmentUUID}/receipt.pdf`,
    {
      responseType: 'blob',
      ...config,
    }
  )
  return data
}

export const uploadFile = async (file: File) => {
  const formData = new FormData()

  formData.append('input_file', file)

  const { data } = await httpClient.post(`/bulk/upload-shipments`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

  return data
}

export const getShipmentsUploadErrorReport = async (
  bulkShipmentsUploadUUID: string
) => {
  const { data } = await httpClient.get(
    `/bulk/upload-shipments/${bulkShipmentsUploadUUID}/error-report`,
    {
      responseType: 'blob',
    }
  )
  return data
}

export const getShipmentsUploadProgress = async (
  bulkShipmentsUploadUUID: string | undefined
) => {
  if (bulkShipmentsUploadUUID) {
    const { data } = await httpClient.get(
      `/bulk/upload-shipments/${bulkShipmentsUploadUUID}`
    )
    return data
  }
}

export const beginImportedShipmentsCreation = async (
  bulkShipmentsUploadUUID: string
) => {
  const { data } = await httpClient.patch(
    `/bulk/upload-shipments/${bulkShipmentsUploadUUID}/trigger-shipments-creation`
  )
  return data
}

export const getShipmentPOD = async (shipmentUUID: string) => {
  const { data } = await httpClient.get(
    `/shipments/${shipmentUUID}/proof-of-delivery`
  )

  return data
}

export const requestShipmentUpdates = async (shipmentUUID: string) => {
  const { data } = await httpClient.get(
    `/shipments/${shipmentUUID}/request-carrier-for-updates`
  )

  return data
}

export const getShipmentNotifications = async () => {
  const { data } = await httpClient.get(`/notifications/shipments`)

  return data
}

export const updateNotificationLastInteraction = async (
  notificationRef: string
) => {
  const { data } = await httpClient.patch(
    `/notifications/${notificationRef}/last-interaction`
  )
  return data
}

export const reactivateShipment = async (shipmentUUID: string) => {
  const { data } = await httpClient.post(
    `/shipments/${shipmentUUID}/reactivate`
  )
  return data
}

export const checkBulkTenderAvailability = async (body: {
  shipments_uuids: string[]
}): Promise<BulkTenderAvailabilityResponse> => {
  const { data } = await httpClient.post(
    `shipments/bulk-tender-action/availability`,
    body
  )

  return data
}

export const bulkTender = async (body: {
  shipments_uuids: string[]
  tender_acceptance_deadline_minutes: number
}): Promise<BulkTenderResponse> => {
  const { data } = await httpClient.post(`/shipments/bulk-tender-action`, body)

  return data
}

export const requestScheduling = async (shipmentUUID: string) => {
  const { data } = await httpClient.post(
    `shipments/${shipmentUUID}/request-scheduling`
  )
  return data
}

export const checkBulkRequestSchedulingAvailability = async (body: {
  shipment_uuids: string | string[]
  filters: ShipmentsListFilters | undefined
}): Promise<BulkRequestSchedulingAvailabilityResponse> => {
  const payload = requestSchedulingMapper(body.shipment_uuids, body.filters)

  const { data } = await httpClient.post(
    `shipments/bulk-request-scheduling-action/availability`,
    payload
  )

  return data
}

export const bulkRequestScheduling = async (body: {
  shipment_uuids: string | string[]
  filters: ShipmentsListFilters | undefined
}) => {
  const payload = requestSchedulingMapper(body.shipment_uuids, body.filters)

  const { data } = await httpClient.post(
    `/shipments/bulk-request-scheduling-action`,
    payload
  )

  return data
}

export type ShipmentDocumentType =
  | 'bill-of-lading'
  | 'weight-certificate'
  | 'inspection-certificate'
  | 'letter-of-authorization'
  | 'lumper-certificate'
  | 'packing-slip'
  | 'attachment'
  | 'qr-code'
  | 'other'
export type ShipmentDocument = {
  type: ShipmentDocumentType
  file_name?: string
  file_url?: string
}

export const getShipmentDocuments = async (shipmentUUID: string) => {
  const { data } = await httpClient.get<ShipmentDocument[]>(
    `/shipments/${shipmentUUID}/documents`
  )

  return data
}

export const checkTags = async (params: CheckTagsPayload) => {
  const { data } = await httpClient.post<CheckTag[]>(
    '/shipments/bulk-manage-tags/check-tags',
    params
  )

  return data
}

export const bulkManageTags = async (params: BulkManageTagsPayload) => {
  const { data } = await httpClient.post('/shipments/bulk-manage-tags', params)

  return data
}
