import type { LabelProps } from '@loadsmart/loadsmart-ui'

import type { AddressProps, LocationOrFacility } from 'components/Form'
import StopSearchField from 'components/Form/StopSearchField'
import type { CustomFormikFieldProps } from 'packages/formik-miranda/src'
import { BaseField, CustomFormikField } from 'packages/formik-miranda/src'
import { numberFormatter } from 'utils/numbers'

interface AddressFormikProps
  extends Omit<AddressProps, 'value' | 'onBlur' | 'callback'>,
    Omit<CustomFormikFieldProps, 'children' | 'id'> {
  readonly stopIndex: number
  readonly label: string
  readonly labelProps?: Omit<LabelProps, 'htmlFor' | 'required'>
  readonly name: string
  readonly withFacilities?: boolean
  readonly stopType: string
  readonly addNewFacilityOption?: boolean
}

function AddressFormik({ ...props }: AddressFormikProps) {
  return (
    <CustomFormikField {...props}>
      {({
        field: { onBlur: _, value: fieldValue, ...field },
        form,
        baseFieldProps,
        baseProps: {
          callback: __,
          stopIndex: stop_index,
          stopType,
          ...baseProps
        },
      }) => {
        const { setFieldTouched, setFieldValue, touched, errors } = form

        const mappedProps = {
          value: {
            address: fieldValue?.address,
            city: fieldValue?.city,
            state: fieldValue?.state,
            zipcode: fieldValue?.zipcode,
            lat: fieldValue?.lat,
            lng: fieldValue?.lng,
            country: fieldValue?.country,
            company_name: fieldValue?.company_name,
          },
          onFocus: () => null,
          onBlur: () => setFieldTouched(baseProps.id, true),
          callback: (location: LocationOrFacility) => {
            setFieldTouched(baseProps.id, true)

            if (!location) {
              setFieldValue(baseProps.id, {
                address: '',
                city: '',
                state: '',
                zipcode: '',
                country: 'USA',
                stop_index,
                type: stopType,
              })

              return
            }

            if (location.isFacility) {
              setFieldValue(baseProps.id, {
                address: location.address,
                city: location.city,
                state: location.state,
                zipcode: location.zipcode,
                lat: numberFormatter(null, 6),
                lng: numberFormatter(null, 6),
                country: location.country,
                facility_uuid: location.uuid,
                company_name: location.company_name,
                type: stopType,
                stop_index,
              })
              return
            }

            const address = props.validateAddress
              ? props.validateAddress(location) && location.address
              : null

            setFieldValue(baseProps.id, {
              ...(address && { address }),
              city: location.city,
              state: location.state,
              zipcode: location.zipcode,
              lat: numberFormatter(location.lat, 6),
              lng: numberFormatter(location.lng, 6),
              country: location.country,
              type: stopType,
              stop_index,
            })
          },
          error: touched[baseProps.id] && !!errors[baseProps.id],
          ...field,
          ...baseProps,
        }

        return (
          <BaseField {...baseFieldProps}>
            <StopSearchField {...mappedProps} />
          </BaseField>
        )
      }}
    </CustomFormikField>
  )
}

export default AddressFormik
