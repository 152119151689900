import { Select } from '@loadsmart/loadsmart-ui'
import type { GenericOption } from '@loadsmart/loadsmart-ui/dist/components/Select/Select.types'
import type { Selectable } from '@loadsmart/loadsmart-ui/dist/hooks/useSelectable'
import type EventLike from '@loadsmart/loadsmart-ui/dist/utils/types/EventLike'
import { Text } from '@loadsmart/miranda-react'
import type {
  FetchLocationOptions,
  GeocodedLocation,
} from '@loadsmart/react-location-select-beta'

import StopSelectOption from 'components/Form/StopSelectOption'
import { LocationSelect } from 'components/LocationSelectBeta'
import { useSelectFacilitySearchMagazzino } from 'hooks/useSelectFacilitySearch'
import { formatFacilitySelectOption } from 'utils/address'
import analytics, { AnalyticsEvent } from 'utils/analytics'

import type { AddressProps } from '../Form/types'

function CreateFacilityOption() {
  return (
    <Select.CreatableOption>
      <Text variant="body-md-bold" color="color-text-link">
        Address not found, add manually
      </Text>
    </Select.CreatableOption>
  )
}

const locationSelectConfig: FetchLocationOptions = {
  types: ['address', 'geocode', 'establishment'],
  restrictions: { country: ['us', 'ca'] },
}

export interface FacilitySearchFieldProps extends AddressProps {
  readonly config?: Partial<FetchLocationOptions>
  readonly onAddManually: (query: string) => void
}

function getSelectInputProps() {
  return {
    maxLength: 200,
  }
}

export default function FacilitySearchField({
  onChange,
  callback,
  onAddManually,
  value,
  ...rest
}: FacilitySearchFieldProps) {
  const selectValue: GenericOption & Selectable = {
    _type: 'address',
    label: formatFacilitySelectOption(value),
    value: formatFacilitySelectOption(value),
  }

  return (
    <LocationSelect
      name="facilitySelect"
      datasources={[useSelectFacilitySearchMagazzino]}
      onChange={(event: EventLike<Selectable | Selectable[] | null>) => {
        const location = event.target.value as GeocodedLocation
        onChange?.(location?.address || '')
        callback(location)
      }}
      placeholder="Insert Facility address"
      getInputProps={getSelectInputProps}
      isValidNewOption
      createOptionPosition="first"
      onCreate={(query) => {
        analytics.track(AnalyticsEvent.FacilityManagementAddressNotFound)
        onAddManually(query)
      }}
      value={selectValue?.value ? selectValue : null}
      components={{
        CreatableOption: CreateFacilityOption,
        Option: StopSelectOption,
      }}
      config={locationSelectConfig}
      {...rest}
    />
  )
}
