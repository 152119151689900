import { IconAttention } from '@loadsmart/icons'
import type {
  LabelProps,
  TextareaProps as MTextareaProps,
} from '@loadsmart/loadsmart-ui'
import { Textarea as MTextarea } from '@loadsmart/loadsmart-ui'
import type { TextFieldProps as MTextFieldProps } from '@loadsmart/loadsmart-ui/dist/components/TextField/TextField'
import type EventLike from '@loadsmart/loadsmart-ui/dist/utils/types/EventLike'
import { useLayoutEffect, useRef, useState } from 'react'

import { BaseField } from './BaseField'
import { CustomFormikField } from './CustomFormikField'
import type { CustomFormikFieldProps } from './CustomFormikField'

export interface ExpandableTextareaProps
  extends Omit<
      MTextareaProps,
      'name' | 'value' | 'form' | 'className' | 'id' | 'required'
    >,
    Omit<CustomFormikFieldProps, 'children'> {
  readonly label: string
  readonly labelProps?: Omit<LabelProps, 'htmlFor' | 'required'>
  readonly name: string
}

export function ExpandableTextarea({ ...props }: ExpandableTextareaProps) {
  const textAreaRef = useRef<HTMLTextAreaElement>(null)

  const [value, setValue] = useState('')
  const [textAreaHeight, setTextAreaHeight] = useState('auto')
  const [parentHeight, setParentHeight] = useState('auto')

  useLayoutEffect(() => {
    setParentHeight(`${textAreaRef.current?.scrollHeight}px`)
    setTextAreaHeight(`${textAreaRef.current?.scrollHeight}px`)
  }, [value])

  return (
    <div
      style={{
        minHeight: parentHeight,
      }}
    >
      <CustomFormikField {...props}>
        {({
          field: { onChange: fieldOnChange, ...field },
          baseFieldProps,
          baseProps,
          form: { setFieldTouched },
        }) => {
          setValue(field.value)
          setParentHeight(`${textAreaRef.current?.scrollHeight}px`)
          setTextAreaHeight(`${textAreaRef.current?.scrollHeight}px`)

          const mappedProps = {
            ...baseProps,
            status:
              props.status ??
              ((baseFieldProps.error
                ? 'danger'
                : undefined) as MTextFieldProps['status']),
            trailing:
              props.trailing ??
              (baseFieldProps.error ? (
                <IconAttention width={12} height={12} />
              ) : undefined),
            onChange: (event: EventLike<string>) => {
              fieldOnChange(event)
              setFieldTouched(field.name, true, false)

              setTextAreaHeight('auto')
              setParentHeight(`${textAreaRef.current?.scrollHeight}px`)
              setValue(event.target.value)
            },
            ...field,
            ...props,
          }

          return (
            <BaseField {...baseFieldProps}>
              <MTextarea
                {...mappedProps}
                ref={textAreaRef}
                style={{
                  height: textAreaHeight,
                  overflow: 'hidden',
                  minHeight: '40px',
                }}
              />
            </BaseField>
          )
        }}
      </CustomFormikField>
    </div>
  )
}
