import type { LabelProps } from '@loadsmart/loadsmart-ui'
import type { Selectable } from '@loadsmart/loadsmart-ui/dist/hooks/useSelectable'
import type EventLike from '@loadsmart/loadsmart-ui/dist/utils/types/EventLike'

import type { LocationSelectProps } from 'components/LocationSelect'
import { BaseField } from 'packages/formik-miranda/src'
import type { CustomFormikFieldProps } from 'packages/formik-miranda/src/CustomFormikField'
import { CustomFormikField } from 'packages/formik-miranda/src/CustomFormikField'

import { StyledLocationSelect } from './styles'

export interface LocationSelectFormikProps
  extends LocationSelectProps,
    Omit<CustomFormikFieldProps, 'children' | 'className' | 'id'> {
  readonly label?: string
  readonly labelProps?: Omit<LabelProps, 'htmlFor' | 'required'>
  readonly name: string
}

function LocationSelectFormik({ ...props }: LocationSelectFormikProps) {
  return (
    <CustomFormikField {...props}>
      {({
        field: { onChange: _, ...field },
        form,
        baseFieldProps,
        baseProps,
      }) => {
        const { setFieldTouched, setFieldValue } = form

        const mappedProps = {
          onChange: ({
            target: { value },
          }: EventLike<Selectable | Selectable[] | null>) => {
            if (!value) {
              setFieldValue(field.name, props.multiple ? [] : null)
              setFieldTouched(field.name, true, false)
              return
            }

            setFieldValue(field.name, value)
            setFieldTouched(field.name, true, false)
          },
          ...field,
          ...baseProps,
          $hasError: Boolean(baseFieldProps.error),
        }

        return (
          <BaseField {...baseFieldProps}>
            <StyledLocationSelect {...mappedProps} />
          </BaseField>
        )
      }}
    </CustomFormikField>
  )
}

export default LocationSelectFormik
