import { Layout, Banner, LogoLoader } from '@loadsmart/miranda-react'
import type { PropsWithChildren } from 'react'
import { useMemo } from 'react'

import type { FacilityDetailsV2 } from 'services/facilities'

import type { FacilityAddressProps } from './FacilityAddress'
import { FacilityAddress } from './FacilityAddress'
import type { FacilityCountryProps } from './FacilityCountry'
import { FacilityCountry } from './FacilityCountry'
import { FacilityEditButton } from './FacilityEditButton'
import { FacilityHoursOfOperationCollapsible } from './FacilityHoursOfOperationCollapsible'
import { FacilityLocation } from './FacilityLocation'
import { FacilityName } from './FacilityName'
import { FacilityOpendockConnected } from './FacilityOpendockConnected'
import {
  FacilitySummaryProvider,
  useFacilitySummaryContext,
} from './FacilitySummary.context'
import { useFacilityDetailsV2 } from './useFacilityDetailsV2'

function ContextBasedFacilityName() {
  const { facility } = useFacilitySummaryContext()

  return <FacilityName name={facility.name} />
}

function ContextBasedFacilityAddress(
  props?: Omit<FacilityAddressProps, 'address' | 'address_details'>
) {
  const { facility } = useFacilitySummaryContext()

  return (
    <FacilityAddress
      address={facility.address}
      address_details={facility.address_details}
      {...props}
    />
  )
}

function ContextBasedFacilityLocation() {
  const { facility } = useFacilitySummaryContext()

  return (
    <FacilityLocation
      city={facility.city}
      state={facility.state}
      zipcode={facility.zipcode}
    />
  )
}

function ContextBasedFacilityCountry(
  props?: Omit<FacilityCountryProps, 'country'>
) {
  const { facility } = useFacilitySummaryContext()

  return <FacilityCountry country={facility.country} {...props} />
}

function ContextBasedFacilityOpendockConnected() {
  const { facility } = useFacilitySummaryContext()

  return <FacilityOpendockConnected warehouse_uuid={facility.warehouse_uuid} />
}

function ContextBasedFacilityHoursOfOperation() {
  const { facility } = useFacilitySummaryContext()

  return (
    <FacilityHoursOfOperationCollapsible
      hours_of_operation={facility.hours_of_operation}
    />
  )
}

function ContextBasedFacilityEditButton() {
  const { facility } = useFacilitySummaryContext()

  return <FacilityEditButton uuid={facility.uuid} />
}

export type FacilitySummaryProps = PropsWithChildren<
  Nullable<{ facility: FacilityDetailsV2; facilityUUID: string }>
>

type FacilitySummaryContentProps = FacilitySummaryProps & {
  readonly isLoading: boolean
  readonly isError: boolean
  readonly refetch: () => void
}

function FacilitySummaryContent({
  facility,
  facilityUUID,
  isLoading,
  isError,
  refetch,
  children,
}: FacilitySummaryContentProps) {
  if (!facility && !facilityUUID) {
    return null
  }

  if (isLoading) {
    return (
      <Layout.Group justify="center">
        <LogoLoader aria-label="Loading facility details" size="32px" />
      </Layout.Group>
    )
  }

  if (isError) {
    return (
      <Banner variant="danger">
        <Banner.Title>Failed to load the facility details</Banner.Title>
        <Banner.Actions>
          <Banner.ActionPrimary onClick={() => refetch()}>
            Try again
          </Banner.ActionPrimary>
        </Banner.Actions>
      </Banner>
    )
  }

  return children
}

export function FacilitySummary({
  facility,
  facilityUUID,
  children,
}: FacilitySummaryProps) {
  /**
   * TODO: This is a temporary solution while we're migrating other components to use the new API.
   * Ideally, we should be able to use the `facility` prop directly since it should be fetched by the parent component.
   */
  const { data, isLoading, isError, refetch } = useFacilityDetailsV2(
    facilityUUID,
    {
      enabled: !facility && facilityUUID != null,
    }
  )

  const contextValue = useMemo(() => {
    if (facility) {
      return { facility }
    }
    if (data) {
      return { facility: data }
    }
    // Empty object as a default value so we don't mess with types, but considering the FacilitySummaryContent validations, consumers can never access an empty facility
    return { facility: {} as FacilityDetailsV2 }
  }, [facility, data])

  return (
    <FacilitySummaryProvider value={contextValue}>
      <FacilitySummaryContent
        facility={facility}
        facilityUUID={facilityUUID}
        isLoading={isLoading}
        isError={isError}
        refetch={refetch}
      >
        {children}
      </FacilitySummaryContent>
    </FacilitySummaryProvider>
  )
}

FacilitySummary.Name = ContextBasedFacilityName
FacilitySummary.Address = ContextBasedFacilityAddress
FacilitySummary.FacilityLocation = ContextBasedFacilityLocation
FacilitySummary.Country = ContextBasedFacilityCountry
FacilitySummary.OpendockConnected = ContextBasedFacilityOpendockConnected
FacilitySummary.HoursOfOperation = ContextBasedFacilityHoursOfOperation
FacilitySummary.EditButton = ContextBasedFacilityEditButton
