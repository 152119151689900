import { useMemo } from 'react'
import type {
  QueryFunctionContext,
  QueryObserverOptions,
  UseQueryResult,
} from 'react-query'
import { useQuery, useQueries } from 'react-query'
import { toast } from 'react-toastify'

import type { SettingsProviderValue } from 'contexts/settings'
import type { FreightWavesData } from 'freightwaves/types'
import type { QueryParams } from 'rfp/components/table/laneParams'
import type {
  RequestForProposal,
  RequestForProposalDetail,
  RequestForProposalOverview,
} from 'rfp/rfp.types'
import { listAccessorials } from 'services/accessorials'
import { fetchUserData } from 'services/auth'
import { fetchCarrierInvites } from 'services/carrierInvite'
import {
  carrierDetails,
  carrierAccountDetails,
  downloadSample,
  listPaginated,
  fetchCarriersEntities,
  fetchCarriersOperationRegions,
  fetchCarriersTags,
  fetchCarriersFiltered,
  fetchCarrierEmployees,
  list,
  listAttachments,
  getAttachmentDownloadURL,
} from 'services/carriers'
import {
  getSubscription,
  list as listFreightWavesLanes,
} from 'services/freightWaves'
import {
  fetchAwardScenarios,
  fetchAwardScenariosStats,
  fetchLaneBidStatusOptions,
  fetchLaneEquipmentTypeOptions,
  fetchPrimaryCarriers,
  fetchRankScenarios,
  fetchRankScenariosStats,
  listPaginated as listLanesPaginated,
  listPrimaryCarriersLanes,
} from 'services/lane'
import {
  fetchLaneManagementAwardSummary,
  fetchLaneManagementCarrierAwards,
  fetchLaneManagementLaneStats,
  fetchLaneManagementProposals,
  fetchLastRFPs,
  getLaneManagement,
} from 'services/laneManagement'
import { list as listLaneProposals } from 'services/laneProposal'
import { priceItemTypes } from 'services/priceItemTypes'
import type { PriceItemTypesOptions } from 'services/priceItemTypes'
import {
  fetchAwardAcceptanceDeadlines,
  fetchProposalCarrierInviteStates,
  fetchExtraChargeTypes,
  fetchProposalsEquipmentTypes,
  fetchProposalsBidStatus,
  fetchProposalsRateTypes,
  fetchProposalsTimePeriods,
  fetchProposalsModes,
} from 'services/proposals'
import { getQuote } from 'services/quote'
import {
  retrieve,
  getModes,
  getTimePeriods,
  getRateTypes,
  getEquipmentTypes,
  getAwardAcceptanceDealines,
  getCurrencies,
  totalizers,
  getBidTypes,
  getEquipmentSizes,
  getDistanceTypes,
  list as getRFPList,
  getEquipmentTypesGroupedByMode,
} from 'services/rfp'
import { get as getFrontendSettings } from 'services/settings'
import {
  getShipperFacilities,
  getFacilityDetails,
  getShipmentLocations,
  getShipmentPOD,
} from 'services/shipments'
import type { ShipmentLocations } from 'services/shipments'
import type { ShipperFavoriteAcsParams } from 'services/shipper'
import {
  getShipperCreditLine,
  getShipperCreditCards,
  get as fetchShipperInfo,
  getShipperRfpQuota,
  getCarrierInvitePerRfpQuota,
  getShipperQuotesQuota,
  getBillingInfo,
  getShipperLocations,
  getShipperLocationsSettings,
  getShipperFavoriteAccessorials,
} from 'services/shipper'
import { get as fetchShipperSettings } from 'services/shipperSettings'
import { fetchTenderRejectionPredictions } from 'services/spotQuote'
import type { LanePrimaryAwardStatus } from 'services/types'
import { downloadData } from 'utils/download'

export const useUserData = ({ enabled }: { enabled: boolean }) => {
  return useQuery({
    queryKey: ['userData'],
    queryFn: ({ signal }) => fetchUserData({ signal }),
    enabled,
    staleTime: Infinity,
  })
}

export const useShipperSettings = () => {
  return useQuery({
    queryKey: ['shipperSettings'],
    queryFn: () => fetchShipperSettings(),
    refetchInterval: 1000 * 60 * 2, // 2 Minutes
    refetchIntervalInBackground: true,
  })
}

export type RFPMode = { label: string; value: string }
export const useRfpModes = (
  options?: QueryObserverOptions<unknown, unknown, RFPMode[]>
) => {
  return useQuery<unknown, unknown, RFPMode[]>({
    refetchInterval: false,
    refetchOnWindowFocus: false,
    ...options,
    queryKey: ['modes'],
    queryFn: getModes,
  })
}

export const useRfpRateTypes = () => {
  return useQuery({
    queryKey: ['rateTypes'],
    queryFn: getRateTypes,
    refetchInterval: false,
    refetchOnWindowFocus: false,
  })
}

export const useRfpTimePeriods = () => {
  return useQuery({
    queryKey: ['timePeriods'],
    queryFn: getTimePeriods,
    refetchInterval: false,
    refetchOnWindowFocus: false,
  })
}

export const useRfpEquipmentTypes = (mode?: string | string[]) => {
  return useQuery({
    queryKey: ['equipmentTypes', mode],
    queryFn: () => getEquipmentTypes(mode),
    refetchInterval: false,
    refetchOnWindowFocus: false,
  })
}

export const useModeEquipmentTypes = () => {
  const { data, isLoading } = useQuery<{ [key: string]: SelectOption[] }>({
    queryKey: ['modeEquipmentTypes'],
    queryFn: getEquipmentTypesGroupedByMode,
    refetchInterval: false,
    refetchOnWindowFocus: false,
  })
  return { data, isLoading }
}

export const useRfpEquipmentSizes = () => {
  return useQuery({
    queryKey: ['equipmentSizes'],
    queryFn: getEquipmentSizes,
    refetchInterval: false,
    refetchOnWindowFocus: false,
  })
}

export const useRfpAwardAcceptanceDeadlines = () => {
  return useQuery({
    queryKey: ['awardAcceptanceDealines'],
    queryFn: getAwardAcceptanceDealines,
    refetchInterval: false,
    refetchOnWindowFocus: false,
  })
}

export const useRfpCurrencies = () => {
  return useQuery({
    queryKey: ['currencies'],
    queryFn: getCurrencies,
    refetchInterval: false,
    refetchOnWindowFocus: false,
  })
}

export const useRfpBidTypes = () => {
  return useQuery({
    queryKey: ['getBidTypes'],
    queryFn: () => getBidTypes(),
    refetchInterval: false,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    retry: false,
  })
}

export const useRfpRetrieve = (
  rfpId: string | number,
  options: QueryObserverOptions<RequestForProposalDetail>
) => {
  return useQuery<RequestForProposalDetail>({
    ...options,
    queryKey: ['retrieveRFP', rfpId],
    queryFn: () => retrieve(rfpId),
  })
}

export const useRFPTotalizers = (rfpId: number | string) => {
  return useQuery<RFPTotalizers>({
    queryKey: ['rfpTotalizers', rfpId],
    queryFn: () => totalizers(rfpId),
  })
}

export const useLaneProposalsRetrieve = (
  laneId: number,
  options: QueryObserverOptions<LaneProposal[]>
) => {
  return useQuery<LaneProposal[]>({
    ...options,
    queryKey: ['retrieveLaneProposal', laneId],
    queryFn: () => listLaneProposals(laneId),
  })
}

export const useSettingsRetrieve = (
  options: QueryObserverOptions<Omit<SettingsProviderValue, 'isLoading'>>
) => {
  return useQuery<Omit<SettingsProviderValue, 'isLoading'>>({
    ...options,
    queryKey: ['retrieveSettings'],
    queryFn: () => getFrontendSettings(),
  })
}

export const useCarrierRetrieve = (
  queryParams?: Record<string, string>,
  options: QueryObserverOptions<Carrier[]> = {}
) => {
  return useQuery<Carrier[]>({
    queryKey: ['retrieveAllCarriers', queryParams],
    queryFn: () => list(queryParams),
    ...options,
  })
}

export const useCarrierRetrievePaginated = (
  query: string,
  limit: string,
  offset: string,
  sort: { column: string; direction: string },
  filter: string,
  rfpId: string | number | null,
  options: QueryObserverOptions<PaginatedResult>
) => {
  return useQuery<PaginatedResult>({
    ...options,
    queryKey: ['retrieveCarriers', query, limit, offset, sort, filter, rfpId],
    queryFn: () => listPaginated(query, limit, offset, sort, filter, rfpId),
  })
}

type UseCarrierFilterOptions = {
  tagFiltersQuery: string
  query: string
  limit: string
  offset: string
  sort: { column: string; direction: string }
  filter: string
  rfpId: string | number | null
}

export const useCarriersFilter = (
  fetchOptions: UseCarrierFilterOptions,
  options: QueryObserverOptions<PaginatedResult>
) => {
  return useQuery<PaginatedResult>({
    ...options,
    queryKey: ['carriersFilter', fetchOptions],
    queryFn: () => fetchCarriersFiltered(fetchOptions),
  })
}

export const useCarrierInvites = (
  rfpId: string | number,
  options: Omit<
    QueryObserverOptions<Array<string | number>>,
    'queryKey' | 'queryFn'
  >
) => {
  return useQuery<Array<string | number>>({
    ...options,
    queryKey: ['carrierInvites', rfpId],
    queryFn: () => fetchCarrierInvites(rfpId),
  })
}

export const useDownloadCarrierTemplate = () => {
  const query = useQuery({
    queryKey: ['carrierTemplate'],
    queryFn: downloadSample,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    enabled: false,
    onSuccess: (carrierTemplate) => {
      downloadData(
        carrierTemplate.data,
        'Carriers Template.csv',
        carrierTemplate.type
      )
      toast.success('Template downloaded successfully')
    },
  })

  return query
}

export const useQuoteRetrieve = (quoteUUID: string) => {
  return useQuery({
    queryKey: ['retrieveQuote', { quoteUUID }],
    queryFn: () => getQuote(quoteUUID),
    refetchInterval: false,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
  })
}

export const useCarrierDetails = (
  carrierId?: string | number,
  options?: QueryObserverOptions<Carrier, Error, Carrier>
) => {
  return useQuery({
    enabled: !!carrierId,
    queryKey: ['carrierDetails', carrierId],
    queryFn: () => carrierDetails(carrierId!),
    refetchOnWindowFocus: false,
    ...options,
  })
}

export const useCarrierAccountDetails = () => {
  return useQuery({
    queryKey: ['carrierAccountDetails'],
    queryFn: () => carrierAccountDetails(),
  })
}

export const useLaneManagement = (
  status: string,
  limit: string | number,
  offset: number | string,
  query: string
) => {
  return useQuery<PaginatedResult>({
    queryKey: ['laneManagementPaginated', status, limit, offset, query],
    queryFn: () => getLaneManagement(status, limit, offset, query),
  })
}

export const useLastRFPs = (groupLaneIds: number[]) => {
  const { data, isLoading } = useQuery<RequestForProposalOverview[]>({
    queryKey: ['getLastRFPs', groupLaneIds],
    queryFn: () => fetchLastRFPs(groupLaneIds),
  })
  return { data, isLoading }
}

export const useShipmentLocations = (
  shipmentUUID: string,
  offset?: number,
  limit?: number
) => {
  return useQuery<ShipmentLocations>({
    queryKey: ['retrieveShipmentLocations', shipmentUUID, offset, limit],
    queryFn: () => getShipmentLocations(shipmentUUID, offset, limit),
    refetchInterval: false,
    refetchOnWindowFocus: false,
  })
}

export const useShipperCreditLine = () => {
  return useQuery({
    queryKey: ['retrieveShipperCreditLine'],
    queryFn: () => getShipperCreditLine(),
    refetchInterval: false,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    retry: false,
  })
}

export const useShipperCreditCards = () => {
  return useQuery({
    queryKey: ['getShipperCreditCards'],
    queryFn: () => getShipperCreditCards(),
    refetchInterval: false,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    retry: false,
  })
}

export const useShipperRfpQuota = (uuid: string) => {
  return useQuery({
    queryKey: ['getShipperRfpQuota', uuid],
    queryFn: () => getShipperRfpQuota(uuid),
    enabled: !!uuid,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    retry: false,
  })
}

export const useCarrierInvitePerRfpQuota = (uuid: string) => {
  return useQuery({
    queryKey: ['getCarrierInvitePerRfpQuota', uuid],
    queryFn: () => getCarrierInvitePerRfpQuota(uuid),
    enabled: !!uuid,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    retry: false,
  })
}

export const useShipperFacilities = (
  options?: { enabled?: boolean; refetchOnWindowFocus?: boolean },
  params?: { search?: string; sort?: string }
): UseQueryResult<Array<Facility>, Error> => {
  return useQuery({
    refetchInterval: false,
    ...options,
    queryKey: ['retrieveShipperFacilities', params],
    queryFn({ signal }) {
      return getShipperFacilities(params, { signal })
    },
  })
}

export const useFacilityDetails = (
  uuid?: string,
  options?: {
    enabled?: boolean
    refetchOnWindowFocus?: boolean
    staleTime?: number
    retry?: boolean
  }
): UseQueryResult<FacilityDetails, Error> => {
  return useQuery({
    enabled: !!uuid,
    refetchInterval: false,
    staleTime: 0,
    retry: false,
    refetchOnWindowFocus: false,
    ...options,
    queryKey: ['getFacilityDetails', uuid],
    queryFn({ signal }) {
      return getFacilityDetails(uuid!, { signal })
    },
  })
}

export const useMultipleFacilityDetails = (
  uuids: string[],
  enabled: boolean = true
) => {
  const queries = useQueries(
    uuids.map((uuid) => ({
      queryKey: ['getFacilityDetails', uuid],
      staleTime: 0,
      refetchOnWindowFocus: false,
      enabled,
      queryFn: ({ signal }: QueryFunctionContext) => {
        return getFacilityDetails<FacilityDetails>(uuid, { signal })
      },
    }))
  )

  const data = []

  for (const query of queries) {
    if (!query.isLoading && query.isError) {
      return { data: undefined, isLoading: false, isError: true }
    }
    if (query.isLoading || !query.data) {
      return { data: undefined, isLoading: true, isError: false }
    }

    data.push(query.data)
  }

  return { data, isLoading: false, isError: false }
}

interface UseAccessorialsOptions {
  enabled?: boolean
  refetchOnWindowFocus?: boolean
}

export const useAccessorials = (
  mode?: TransportationModeCode | TransportationModeCode[],
  opts?: UseAccessorialsOptions
) => {
  const { data, isLoading } = useQuery<Accessorial[]>({
    ...opts,
    queryKey: ['listAccessorials', mode],
    queryFn: () => listAccessorials(mode),
  })

  return { data, isLoading }
}

export const usePriceItemTypes = (
  options?: PriceItemTypesOptions,
  queryOptions?: QueryObserverOptions<PriceItemType[]>
) => {
  const { status, data, isLoading, isError, refetch } = useQuery<
    PriceItemType[]
  >({
    refetchOnWindowFocus: false,
    ...queryOptions,
    queryKey: ['priceItemTypes', options],
    queryFn: () => priceItemTypes(options),
  })

  const priceItemTypeOptions = useMemo(
    () =>
      (data ?? []).map((priceItemType) => {
        return {
          label: priceItemType.label,
          value: priceItemType.uuid,
        }
      }),
    [data]
  )

  return { status, data, priceItemTypeOptions, isLoading, isError, refetch }
}

export const useLaneManagementAwardSummary = (groupLaneIds: number[]) => {
  const { data, isLoading } = useQuery<{
    rfps: RequestForProposalOverview[]
    total_volume: number
  }>({
    queryKey: ['getLaneManagementAwardSummary', groupLaneIds],
    queryFn: () => fetchLaneManagementAwardSummary(groupLaneIds),
  })

  return { data, isLoading }
}

export const useLaneManagementCarrierAwards = (groupLaneIds: number[]) => {
  const { data, isLoading } = useQuery<RequestForProposalOverview[]>({
    queryKey: ['getLaneManagementCarrierAwards', groupLaneIds],
    queryFn: () => fetchLaneManagementCarrierAwards(groupLaneIds),
  })
  return { data, isLoading }
}

export const useLaneManagementProposals = (groupLaneIds: number[]) => {
  const { data, isLoading } = useQuery<RequestForProposalOverview[]>({
    queryKey: ['getLaneManagementProposals', groupLaneIds],
    queryFn: () => fetchLaneManagementProposals(groupLaneIds),
  })
  return { data, isLoading }
}

export const useLaneManagementLaneStats = (groupLaneIds: number[]) => {
  const { data, isLoading } = useQuery<LaneManagementBenchmark>({
    queryKey: ['getLaneManagementLaneStats', groupLaneIds],
    queryFn: () => fetchLaneManagementLaneStats(groupLaneIds),
  })

  return { data, isLoading }
}

type UseAllocatedLaneOptions = {
  rfpId: string | number
  limit: string | number
  offset: number | string
  sort: { column: string; direction: string }
  primaryAwardStatus: LanePrimaryAwardStatus
  query?: string
  filters?: QueryParams['filters']
}

export const useAllocatedLanes = (
  fetchOptions: UseAllocatedLaneOptions,
  options: QueryObserverOptions<PaginatedResult>
) => {
  return useQuery<PaginatedResult>({
    ...options,
    queryKey: ['allocatedLanes', fetchOptions],
    queryFn: () => {
      return listLanesPaginated({
        ...fetchOptions,
        isAllocated: true,
      })
    },
  })
}

export const useRankedLanes = (
  rfpId: string | number,
  limit: string | number,
  offset: number | string,
  sort: { column: string; direction: string },
  options: QueryObserverOptions<PaginatedResult>,
  query?: string
) => {
  return useQuery<PaginatedResult>({
    ...options,
    // We are using the same querykey as regular award scenarios
    // just to reuse the same Websocket and RoutingGuide update code
    queryKey: ['allocatedLanes', rfpId, offset, sort, false, query, limit],
    queryFn: () =>
      listLanesPaginated({
        rfpId,
        limit,
        offset,
        sort,
        isRanked: true,
        query,
      }),
  })
}

export const useFilteredRankedLanes = (
  rfpId: string | number,
  limit: string | number,
  offset: number | string,
  sort: { column: string; direction: string },
  rankedStatus: boolean | undefined,
  options: QueryObserverOptions<PaginatedResult>,
  query?: string
) => {
  return useQuery<PaginatedResult>({
    ...options,
    queryKey: [
      'filteredRankedLanes',
      rfpId,
      offset,
      sort,
      rankedStatus,
      query,
      limit,
    ],
    queryFn: () =>
      listLanesPaginated({
        rfpId,
        limit,
        offset,
        sort,
        isRanked: rankedStatus,
        query,
      }),
  })
}

export const useUnrankedLanes = (
  rfpId: string | number,
  limit: string | number,
  offset: number | string,
  sort: { column: string; direction: string },
  options: QueryObserverOptions<PaginatedResult>,
  query?: string,
  filters?: QueryParams['filters']
) => {
  return useQuery<PaginatedResult>({
    ...options,
    // We are using the same querykey as regular award scenarios
    // just to reuse the same Websocket and RoutingGuide update code
    queryKey: [
      'deallocatedLanes',
      rfpId,
      offset,
      sort,
      true,
      query,
      filters,
      limit,
    ],
    queryFn: () =>
      listLanesPaginated({
        rfpId,
        limit,
        offset,
        sort,
        isRanked: false,
        query,
        filters,
      }),
  })
}

export const useDeallocatedLanes = (
  rfpId: string | number,
  limit: string | number,
  offset: number | string,
  sort: { column: string; direction: string },
  options: QueryObserverOptions<PaginatedResult>,
  query?: string,
  filters?: QueryParams['filters']
) => {
  return useQuery<PaginatedResult>({
    ...options,
    queryKey: ['deallocatedLanes', rfpId, offset, sort, query, filters, limit],
    queryFn: () =>
      listLanesPaginated({
        rfpId,
        limit,
        offset,
        sort,
        isAllocated: false,
        query,
        filters,
      }),
  })
}

export const usePrimaryCarrierLanes = (
  rfpId: string | number,
  carrierId: string | number,
  limit: string | number,
  offset: number | string,
  sort: { column: string; direction: string },
  options: QueryObserverOptions<PaginatedResult>
) => {
  return useQuery<PaginatedResult>({
    ...options,
    queryKey: ['primaryCarriersLanes', rfpId, carrierId, offset, sort, limit],
    queryFn: () =>
      listPrimaryCarriersLanes(rfpId, carrierId, limit, offset, sort),
  })
}

export const useRankScenarios = (
  rfpId: string | number,
  limit: string | number,
  offset: number | string,
  sort: { column: string; direction: string },
  carrierEntity: string,
  topRank: number | null,
  options: QueryObserverOptions<PaginatedResult>
) => {
  //@TODO: connect this properly with the API
  return useQuery<PaginatedResult>({
    ...options,
    queryKey: [
      'rankScenarios',
      rfpId,
      offset,
      sort,
      carrierEntity,
      topRank,
      limit,
    ],
    queryFn: () =>
      //@TODO: Use the proper service method for rankScenarios
      fetchRankScenarios(rfpId, limit, offset, sort, carrierEntity, topRank),
  })
}

type UseAwardScenariosOptions = {
  rfpId: string | number
  limit: string | number
  offset: number | string
  sort: { column: string; direction: string }
  carrierEntity: string
  carrierIncumbency: string
  backupCarrierCount: string
  maxSplitCarriers: string
}

export const useAwardScenarios = (
  fetchOptions: UseAwardScenariosOptions,
  options: QueryObserverOptions<PaginatedResult>
) => {
  return useQuery<PaginatedResult>({
    ...options,
    queryKey: ['awardScenarios', fetchOptions],
    queryFn: () => fetchAwardScenarios(fetchOptions),
  })
}
export const useRankScenariosStats = (
  rfpId: string | number,
  carrierEntity: string,
  topRank: number | null
) => {
  //@TODO: connect this properly with the API
  return useQuery({
    queryKey: ['rankScenariosStats', rfpId, carrierEntity, topRank],
    //@TODO: Use the proper service method for rankScenariosStats
    queryFn: () => fetchRankScenariosStats(rfpId, carrierEntity, topRank),
    refetchOnWindowFocus: false,
  })
}

export const useAwardScenariosStats = (
  rfpId: string | number,
  carrierEntity: string,
  carrierIncumbency: string,
  backupCarrierCount: string,
  maxSplitCarriers: string,
  options: Omit<
    QueryObserverOptions<AwardScenariosStats>,
    'queryKey' | 'queryFn'
  >
) => {
  return useQuery<AwardScenariosStats>({
    refetchOnWindowFocus: false,
    ...options,
    queryKey: [
      'awardScenariosStats',
      rfpId,
      carrierEntity,
      carrierIncumbency,
      backupCarrierCount,
      maxSplitCarriers,
    ],
    queryFn: () => {
      return fetchAwardScenariosStats(
        rfpId,
        carrierEntity,
        carrierIncumbency,
        backupCarrierCount,
        maxSplitCarriers
      )
    },
  })
}

export const usePrimaryCarriers = (rfpId: string) => {
  return useQuery({
    queryKey: ['primaryCarriers', rfpId],
    queryFn: () => fetchPrimaryCarriers(rfpId),
    refetchOnWindowFocus: false,
  })
}

export const useCarriersEntities = () => {
  return useQuery({
    queryKey: ['carriersEntities'],
    queryFn: () => fetchCarriersEntities(),
    retry: false,
    refetchOnWindowFocus: false,
  })
}

export const useCarriersOperationRegions = (
  allowNotSet = true,
  allowCounts = true
) => {
  return useQuery({
    queryKey: ['carriersOperationsRegions', allowNotSet, allowCounts],
    queryFn: () => fetchCarriersOperationRegions(allowNotSet, allowCounts),

    retry: false,
    refetchOnWindowFocus: false,
  })
}

export const useCarriersTags = () => {
  const { data } = useQuery<CarrierTag[]>({
    queryKey: ['carriersTags'],
    queryFn: () => fetchCarriersTags(),
    retry: false,
    refetchOnWindowFocus: false,
  })

  return { tags: data }
}

export const useProposalsEquipmentTypes = () => {
  return useQuery({
    queryKey: ['proposalsEquipmentTypes'],
    queryFn: () => fetchProposalsEquipmentTypes(),
    retry: false,
    refetchOnWindowFocus: false,
  })
}

export const useAwardAcceptanceDeadlines = (rfpId: number) => {
  return useQuery({
    queryKey: ['awardAcceptanceDeadlines', rfpId],
    queryFn: () => fetchAwardAcceptanceDeadlines(rfpId),
    retry: false,
    refetchOnWindowFocus: false,
  })
}

export const useExtraChargeTypes = (options: { enabled?: boolean } = {}) => {
  return useQuery({
    queryKey: ['extraChargeTypes'],
    queryFn: () => fetchExtraChargeTypes(),
    retry: false,
    refetchOnWindowFocus: false,
    ...options,
  })
}

export const useShipperInfo = (options: { enabled?: boolean } = {}) => {
  return useQuery({
    queryKey: ['shipperInfo'],
    queryFn: () => fetchShipperInfo(),
    retry: false,
    refetchOnWindowFocus: false,
    ...options,
  })
}

export const useShipperQuotesQuota = () => {
  return useQuery<QuotesQuota>({
    queryKey: ['getShipperQuotesQuota'],
    queryFn: () => getShipperQuotesQuota(),
    staleTime: Infinity,
  })
}

export const useShipperBillingInfo = () => {
  return useQuery({
    queryKey: ['getShipperBillingInfo'],
    queryFn: () => getBillingInfo(),
    retry: false,
    refetchOnWindowFocus: false,
  })
}

export const useCarrierEmployees = ({
  carrierUUID,
  options,
}: {
  carrierUUID: string
  options: { enabled?: boolean }
}) => {
  return useQuery({
    queryKey: ['carrierEmployees', carrierUUID],
    queryFn: () => fetchCarrierEmployees(carrierUUID),
    retry: false,
    refetchOnWindowFocus: false,
    ...options,
  })
}

export const useShipmentPOD = ({
  shipmentUUID,
  options = {},
}: {
  shipmentUUID: string
  options?: QueryObserverOptions<podType>
}) => {
  return useQuery<podType>({
    retry: false,
    refetchOnWindowFocus: false,
    ...options,
    queryKey: ['getShipmentPOD', shipmentUUID],
    queryFn: () => getShipmentPOD(shipmentUUID),
  })
}

export const useProposalCarrierInviteStates = (rfpId: string | number) => {
  return useQuery({
    queryKey: ['proposalCarrierInviteStates', rfpId],
    queryFn: () => fetchProposalCarrierInviteStates(rfpId),
    retry: false,
    refetchOnWindowFocus: false,
  })
}

export const useLaneBidStatusOptions = (rfpId: string | number) => {
  return useQuery({
    queryKey: ['fetchLaneBidStatusOptions', rfpId],
    queryFn: () => fetchLaneBidStatusOptions(rfpId),
    retry: false,
    refetchOnWindowFocus: false,
  })
}
export const useLaneEquipmentTypeOptions = (rfpId: string | number) => {
  return useQuery({
    queryKey: ['fetchLaneEquipmentTypeOptions', rfpId],
    queryFn: () => fetchLaneEquipmentTypeOptions(rfpId),
    retry: false,
    refetchOnWindowFocus: false,
  })
}

export const useProposalsBidStatus = () => {
  return useQuery({
    queryKey: ['proposalsBidStatus'],
    queryFn: () => fetchProposalsBidStatus(),
    retry: false,
    refetchOnWindowFocus: false,
  })
}

export const useProposalsRateTypes = () => {
  return useQuery({
    queryKey: ['proposalsRateTypes'],
    queryFn: () => fetchProposalsRateTypes(),
    retry: false,
    refetchOnWindowFocus: false,
  })
}
export const useProposalsTimePeriods = () => {
  return useQuery({
    queryKey: ['proposalsTimePeriods'],
    queryFn: () => fetchProposalsTimePeriods(),
    retry: false,
    refetchOnWindowFocus: false,
  })
}

export const useProposalsModes = () => {
  return useQuery({
    queryKey: ['proposalsModes'],
    queryFn: () => fetchProposalsModes(),
    retry: false,
    refetchOnWindowFocus: false,
  })
}

export const useDistanceTypes = () => {
  return useQuery({
    queryKey: ['distanceTypes'],
    queryFn: () => getDistanceTypes(),
    retry: false,
    refetchOnWindowFocus: false,
  })
}

export const useShipperLocations = () => {
  return useQuery<ShipperLocationDict>({
    queryKey: ['shipperLocations'],
    queryFn: () => getShipperLocations(),
    retry: false,
    refetchOnWindowFocus: false,
  })
}

export const useShipperLocationsSettings = (searchQuery: string) => {
  return useQuery<ShipperSettingsLocation[]>({
    queryKey: ['shipperLocationsSettings', searchQuery],
    queryFn: () => getShipperLocationsSettings(searchQuery),
    retry: false,
    refetchOnWindowFocus: false,
  })
}

export const useRFPList = (params: {
  state: string | null
  name?: string
  selected: {
    mode: string
    frequency: string
    rateType: string
    bid: boolean | string
  }
  limit: number
  offset: number
  sort?: string
}) => {
  return useQuery<PaginatedResult<RequestForProposal>>({
    queryKey: [
      'rfp-list',
      params.state,
      params.name,
      params.selected.bid,
      params.selected.frequency,
      params.selected.mode,
      params.selected.rateType,
      params.limit,
      params.offset,
      params.sort,
    ],
    queryFn: () =>
      getRFPList({
        bid: String(params.selected.bid),
        frequency: params.selected.frequency,
        mode: params.selected.mode,
        rateType: params.selected.rateType,
        name: params.name,
        state: params.state ?? undefined,
        limit: params.limit,
        offset: params.offset,
        sort: params.sort,
      }),
    retry: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    // this is necessary to the pagination to work properly
    // more info at: https://tanstack.com/query/v3/docs/react/guides/paginated-queries#better-paginated-queries-with-keeppreviousdata
    keepPreviousData: true,
  })
}

export const useShipperFavoriteAccessorials = (
  options?: ShipperFavoriteAcsParams,
  queryOptions?: QueryObserverOptions<ShipperFavoriteAccessorial[]>
) => {
  return useQuery<ShipperFavoriteAccessorial[]>({
    queryKey: ['shipperFavoriteAccessorials', options],
    queryFn: () => getShipperFavoriteAccessorials(options),
    refetchOnWindowFocus: false,
    ...queryOptions,
  })
}

export const useTenderRejectionPredictions = (
  quoteUUID: string,
  options: {
    enabled: boolean
  }
) => {
  return useQuery({
    queryKey: ['tenderRejectionPredictions', quoteUUID],
    queryFn: () => fetchTenderRejectionPredictions(quoteUUID),
    retry: false,
    refetchOnWindowFocus: false,
    ...options,
  })
}

export const useFreightWavesSubscription = () => {
  return useQuery({
    queryKey: ['freightWavesSubscription'],
    queryFn: () => getSubscription(),
    retry: false,
    refetchOnWindowFocus: false,
    cacheTime: 500, //ms
  })
}

export const useFreightWavesLanes = (params: {
  limit: number
  offset: number
  search?: string
  sort?: string
  options: {
    enabled: boolean
    initialData: PaginatedResult | undefined
    select: (
      data: PaginatedResult<FreightWavesData>
    ) => PaginatedResult<FreightWavesData>
  }
}) => {
  return useQuery({
    queryKey: [
      'freightWavesLanes',
      params.limit,
      params.offset,
      params.search,
      params.sort,
    ],
    queryFn: () =>
      listFreightWavesLanes({
        limit: params.limit,
        offset: params.offset,
        search: params.search,
        order: params.sort,
      }),
    retry: false,
    // this is necessary to the pagination to work properly
    // more info at: https://tanstack.com/query/v3/docs/react/guides/paginated-queries#better-paginated-queries-with-keeppreviousdata
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    ...params.options,
  })
}

export const useCarriersAttachmentsList = (carrierUUID: string) => {
  return useQuery<PaginatedResult<{ id: number; file_name: string }>>({
    queryKey: ['carrierAttachmentsList', carrierUUID],
    queryFn: () => listAttachments(carrierUUID),
    retry: false,
    refetchOnWindowFocus: false,
  })
}

export const useCarrierAttachmentDownloadURL = (
  carrierUUID: string,
  attachmentId: string | undefined
) => {
  return useQuery({
    queryKey: ['carrierAttachmentDownload', carrierUUID, attachmentId],
    queryFn: () => getAttachmentDownloadURL({ carrierUUID, attachmentId }),
    retry: false,
    refetchOnWindowFocus: false,
    enabled: Boolean(attachmentId),
  })
}
