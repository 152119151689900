import { isEmpty } from 'lodash'

import httpClient, { makeRequestCreator } from 'utils/httpClient'

export const CARRIER_ENDPOINT_BASE_URL = '/carriers'

export const list = async (
  queryParams: Record<string, any> = {}
): Promise<Carrier[]> => {
  let params = ''
  if (!isEmpty(queryParams)) {
    const searchParams = new URLSearchParams(queryParams)
    params = `?${searchParams.toString()}`
  }
  const { data } = await httpClient.get(`/carriers${params}`)
  return data as Carrier[]
}

export const listPaginated = async (
  query: string,
  limit: string,
  offset: string,
  sort: { column: string; direction: string },
  filter: string,
  rfpId: string | number | null
) => {
  const urlParams = new URLSearchParams({ limit, offset })
  if (query) {
    urlParams.append('query', query)
  }
  const searchParams = urlParams ? `&${urlParams.toString()}` : ''
  const idParam = rfpId ? `&rfp_id=${rfpId}` : ''
  let sortParam = sort.column
  if (sort.direction === 'desc') {
    sortParam = `-${sort.column}`
  }
  const { data } = await httpClient.get(
    `/carriers?sort=${sortParam}&filter=${filter}${idParam}${searchParams}`
  )
  return data
}
/**
 * @param carrier - the carrier data to be created
 * @param allowArchived - allows the backend to set an archived carrier as accessible
 * @returns the newly created carrier
 */
export const create = async (
  carrier: Partial<Carrier>,
  allowArchived = false
) => {
  let url = CARRIER_ENDPOINT_BASE_URL

  if (allowArchived) {
    url = url.concat('?allow_archived=true')
  }

  const { data } = await httpClient.post<Carrier>(url, carrier)
  return data
}

export const search = async (query: string) => {
  const client = makeRequestCreator(httpClient)
  const searchParams = new URLSearchParams({ q: query })
  const params = query ? `?${searchParams.toString()}` : ''

  return await client(`/carriers/search${params}`)
}

export const aliceCarrierDetails = async (carrierUUID: string) => {
  const { data } = await httpClient.get(`/carriers/search/${carrierUUID}`)
  return data.data
}

export const carrierDetails = async (id: string | number) => {
  const { data } = await httpClient.get<Carrier>(
    `/carriers/${id}?allow_archived=true`
  )
  return data
}

export const carrierAccountDetails = async () => {
  const { data } = await httpClient.get(`/carriers/me`)
  return data
}

export const partialUpdateCarrierDetails = async ({
  id,
  carrier,
  allowArchived = false,
}: {
  id: string | number
  carrier: Partial<Carrier>
  allowArchived?: boolean
}) => {
  let url = `${CARRIER_ENDPOINT_BASE_URL}/${id}`

  if (allowArchived) {
    url = url.concat('?allow_archived=true')
  }

  const { data } = await httpClient.patch(url, carrier)
  return data
}

export const deleteCarrierDetails = async (id: string | number) => {
  const { data } = await httpClient.delete(`${CARRIER_ENDPOINT_BASE_URL}/${id}`)
  return data
}

export const bulkDeleteCarrierDetails = async (
  carriers: Array<string | number>
) => {
  const { data } = await httpClient.delete(`/carriers/bulk_delete`, {
    data: { carriers },
  })
  return data
}

export const upload = async ({
  file,
  rfpId,
}: {
  file: File
  rfpId?: string | number
}) => {
  const formData = new FormData()
  formData.append('file', file)
  if (rfpId) {
    formData.append('rfp', `${rfpId}`)
  }
  const { data } = await httpClient.post(`/carriers/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return data
}

export const downloadSample = async () => {
  const { data, headers } = await httpClient.get(`/carriers/sample`, {
    responseType: 'arraybuffer',
  })
  return { data, type: headers['content-type'] }
}

export const awardConfirmation = async ({
  token,
  action,
}: {
  token: string
  action: string
}) => {
  const { data } = await httpClient.post(
    `/proposals/award-confirmation/${token}/${action}`
  )
  return data
}

export const awardFeedback = async ({
  token,
  award_feedback,
}: {
  token: string
  award_feedback: string
}) => {
  const { data } = await httpClient.post(
    `/proposals/award-confirmation/${token}/feedback`,
    {
      award_feedback,
    }
  )
  return data
}

export const listCarriers = async (
  rfpId: string | number | null,
  sort: any,
  query: string
) => {
  const searchParams = new URLSearchParams({ query })
  const params = query ? `&${searchParams.toString()}` : ''
  let sortParam = sort.column
  if (sort.direction === 'desc') {
    sortParam = `-${sort.column}`
  }
  const { data } = await httpClient.get(
    `/carriers?rfp_id=${rfpId}&sort=${sortParam}${params}`
  )
  return data
}

export const resubscribeCarrier = async ({
  linkUUID,
}: {
  linkUUID: string
}) => {
  return await httpClient.post(`/proposals/${linkUUID}/subscribe`)
}

export const fetchCarriersEntities = async () => {
  const { data } = await httpClient.get(
    `carriers/entities?allow_not_set=true&allow_counts=true`
  )
  return data
}

export const CARRIER_OPERATION_REGIONS_ENDPOINT = 'carriers/operation_regions'
export const fetchCarriersOperationRegions = async (
  allowNotSet: boolean,
  allowCounts: boolean
) => {
  const { data } = await httpClient.get(
    `${CARRIER_OPERATION_REGIONS_ENDPOINT}?allow_not_set=${allowNotSet}&allow_counts=${allowCounts}`
  )
  return data
}

export const fetchCarriersTags = async () => {
  const { data } = await httpClient.get(
    `carriers/tags?allow_not_set=true&allow_counts=true`
  )
  return data
}

type FetchCarriersFilteredOptions = {
  tagFiltersQuery: string
  query: string
  limit: string
  offset: string
  sort: { column: string; direction: string }
  filter: string
  rfpId: string | number | null
}
export const fetchCarriersFiltered = async ({
  tagFiltersQuery,
  query,
  limit,
  offset,
  sort,
  filter,
  rfpId,
}: FetchCarriersFilteredOptions) => {
  const urlParams = new URLSearchParams({ limit, offset })
  if (query) {
    urlParams.append('query', query)
  }
  const searchParams = urlParams ? `&${urlParams.toString()}` : ''
  const idParam = rfpId ? `&rfp_id=${rfpId}` : ''
  const tagFilterParam = tagFiltersQuery !== '' ? `&${tagFiltersQuery}` : ''
  let sortParam = sort.column
  if (sort.direction === 'desc') {
    sortParam = `-${sort.column}`
  }
  const { data } = await httpClient.get(
    `/carriers?sort=${sortParam}&filter=${filter}${idParam}${searchParams}${tagFilterParam}`
  )
  return data
}

export const fetchCarrierEmployees = async (carrierUUID: string) => {
  const { data } = await httpClient.get(`carriers/${carrierUUID}/employees`)
  return data
}

export const createCarrierEmployee = async ({
  carrierUUID,
  employeeData,
}: {
  carrierUUID: string
  employeeData: Partial<{
    name: string
    phone_number: string
    email: string
    roles: string[]
  }>
}) => {
  const { data } = await httpClient.post(
    `carriers/${carrierUUID}/employees`,
    employeeData
  )
  return data
}

export const deleteCarrierEmployee = async ({
  carrierUUID,
  employeeUUID,
}: {
  carrierUUID: string
  employeeUUID: string
}) => {
  const { data } = await httpClient.delete(
    `/carriers/${carrierUUID}/employees/${employeeUUID}`
  )

  return data
}

export const inviteAll = async ({
  rfpId,
  parsedTagFilter,
}: {
  rfpId: string | number
  parsedTagFilter: string
}) => {
  const filtersParam = parsedTagFilter !== '' ? `&${parsedTagFilter}` : ''

  const { data } = await httpClient.post(
    `/carriers/invite_all?rfp_id=${rfpId}${filtersParam}`
  )
  return data
}

export const listAttachments = async (carrierUUID: string) => {
  const { data } = await httpClient.get(`carriers/${carrierUUID}/attachments`)

  return data
}

export const deleteAttachment = async ({
  carrierUUID,
  attachmentUUID,
}: {
  carrierUUID: string
  attachmentUUID: string
}) => {
  const { data } = await httpClient.delete(
    `carriers/${carrierUUID}/attachments/${attachmentUUID}`
  )
  return data
}

export const uploadAttachments = async ({
  carrierUUID,
  files,
}: {
  carrierUUID: string
  files: File[]
}) => {
  const formData = new FormData()
  files.forEach((file) => {
    formData.append('attachments', file)
  })

  const { data } = await httpClient.post(
    `carriers/${carrierUUID}/attachments`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )
  return data
}

export const getAttachmentDownloadURL = async ({
  carrierUUID,
  attachmentId,
}: {
  carrierUUID: string
  attachmentId: string | undefined
}) => {
  const { data } = await httpClient.get(
    `carriers/${carrierUUID}/attachments/${attachmentId}`
  )
  return data
}
