import { Text } from '@loadsmart/loadsmart-ui'

export interface StyledErrorMessageProps {
  readonly error: string | string[]
}

export const StyledErrorMessage = ({ error }: StyledErrorMessageProps) => {
  const renderErrorsList = (errors: string[]) => {
    return (
      <ul>
        {errors.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    )
  }

  return (
    <Text color="color-danger-dark" variant="caption" role="alert">
      {Array.isArray(error) ? renderErrorsList(error) : error}
    </Text>
  )
}
