import type { LabelProps } from '@loadsmart/loadsmart-ui'
import { Label, Layout } from '@loadsmart/loadsmart-ui'
import type { Spacing } from '@loadsmart/loadsmart-ui/dist/components/Layout/Layout.types'
import { Icon, Tooltip } from '@loadsmart/miranda-react'
import type { ReactNode } from 'react'

import { StyledErrorMessage } from './StyledErrorMessage'

export interface BaseFieldProps {
  readonly label?: string
  readonly labelProps?: Omit<LabelProps, 'htmlFor' | 'required'>
  readonly error?: string | any[]
  readonly id?: string
  readonly required?: boolean
  readonly className?: string
  readonly children: ReactNode
  readonly space?: Spacing
  readonly tip?: string
}

const LABEL_STYLE = {
  display: 'flex',
  alignItems: 'center',
  alignContent: 'center',
  verticalAlign: 'middle',
}

export function BaseField({
  error,
  id,
  label,
  labelProps,
  required,
  children,
  className,
  space,
  tip,
}: BaseFieldProps) {
  return (
    <Layout.Stack
      className={className}
      space={space}
      data-testid="formik-base-field"
    >
      {label ? (
        <Layout.Group space="xs" align="center">
          <Label
            htmlFor={id}
            required={required}
            {...labelProps}
            style={LABEL_STYLE}
          >
            {label}
          </Label>
          {tip && (
            <Tooltip message={tip} placement="top" trigger="hover">
              <Icon
                name="question"
                size="small"
                data-testid={`${id}-tooltip`}
              />
            </Tooltip>
          )}
        </Layout.Group>
      ) : null}
      {children}
      {error ? <StyledErrorMessage error={error} /> : null}
    </Layout.Stack>
  )
}
