import type {
  CheckboxProps as MCheckboxProps,
  LabelProps,
} from '@loadsmart/loadsmart-ui'
import { Checkbox as MCheckbox, Label } from '@loadsmart/loadsmart-ui'
import type EventLike from '@loadsmart/loadsmart-ui/dist/utils/types/EventLike'

import { CustomFormikField } from './CustomFormikField'
import { StyledErrorMessage } from './StyledErrorMessage'

export interface CheckboxProps
  extends Omit<MCheckboxProps, 'name' | 'value' | 'form'> {
  readonly label: string
  readonly name: string
  readonly labelProps?: Omit<LabelProps, 'htmlFor' | 'required'>
}

export function Checkbox({ ...props }: CheckboxProps) {
  return (
    <CustomFormikField {...props}>
      {({
        field: { value: fieldValue, onChange: _, ...field },
        error,
        baseProps,
        form,
      }) => {
        const { setFieldTouched, setFieldValue } = form
        const mappedProps = {
          ...baseProps,
          checked: fieldValue,
          onChange: ({ target: { checked } }: EventLike<string>) => {
            setFieldValue(field.name, checked)
            setFieldTouched(field.name, true, false)
          },
          ...field,
          ...props,
        }

        return (
          <>
            <MCheckbox {...mappedProps}>
              <Label
                htmlFor={props.id}
                required={props.required}
                {...props.labelProps}
              >
                {props.label}
              </Label>
            </MCheckbox>
            {error && <StyledErrorMessage error={error} />}
          </>
        )
      }}
    </CustomFormikField>
  )
}
