import type { LabelProps } from '@loadsmart/loadsmart-ui'
import type { Spacing } from '@loadsmart/loadsmart-ui/dist/components/Layout/Layout.types'
import { Field, getIn } from 'formik'
import type { FieldProps } from 'formik'
import type { ReactNode } from 'react'

import type { BaseFieldProps } from './BaseField'

export interface CustomFormikFieldCallableChildrenProps extends FieldProps {
  error?: string | any[]
  baseFieldProps: Omit<BaseFieldProps, 'children'>
  baseProps: any
}

export interface CustomFormikFieldProps {
  readonly label?: string
  readonly name: string
  readonly required?: boolean
  readonly labelProps?: Omit<LabelProps, 'htmlFor' | 'required'>
  readonly id?: string
  readonly className?: string
  readonly space?: Spacing
  readonly children: (
    params: CustomFormikFieldCallableChildrenProps
  ) => ReactNode
  readonly innerComponentClassName?: string
}

export function CustomFormikField({
  children,
  ...props
}: CustomFormikFieldProps) {
  const {
    label,
    name,
    required,
    labelProps,
    id,
    className,
    innerComponentClassName,
    space,
    ...rest
  } = props

  return (
    <Field name={name}>
      {({ field, form, meta }: FieldProps) => {
        const { touched, errors } = form
        const error = getIn(errors, name) ?? errors[name]
        const touch = getIn(touched, name)
        const showError = !!error && !!touch

        const baseProps = {
          disabled: form.isSubmitting,
          className: innerComponentClassName,
          name,
          required,
          id,
          ...rest,
        }

        const baseFieldProps = {
          label,
          error: showError ? error : undefined,
          id,
          required,
          labelProps,
          className,
          space,
        }

        return children({
          field,
          form,
          meta,
          baseFieldProps,
          baseProps,
        })
      }}
    </Field>
  )
}
